import React from "react";
import './chromeUninstallSubmitted.css'
import correct from './correct.gif'

function ChromeSubmitted () {
    return (
        <div className="submit_main">
            <div className="submit_thank_you_area">
                <img src={correct} alt="right"/>
                <h1>Thank you for your response</h1>
            </div>
        </div>
    )
}

export default ChromeSubmitted