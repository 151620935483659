import { useEffect } from 'react'
import './stripePaymentSuccess.css'
import redirectJson from './redirectLottie.json'
import { Player } from '@lottiefiles/react-lottie-player'
import { useNavigate } from 'react-router-dom';

function StripeSuccessPageWindows() {
    const navigate = useNavigate();
    useEffect(() => {
        const timer = setTimeout(() => {
            const link = document.createElement('a');
            link.href = `preventUninstallBlockerXChromeExtension://instantApproval=true`
            document.body.appendChild(link);
            link.click();
            navigate('/')
        }, 1500);
        return () => clearTimeout(timer);
    }, []);
    return (
        <section id='stripe_payment_success_container'>
            <h1>Redirecting you back to the app....</h1>
            {/* <img src={redirect} alt='redirect' /> */}
            <Player
                src={redirectJson}
                className="player"
                loop
                autoplay
            />
        </section>
    )
}

export default StripeSuccessPageWindows;