import './forgotPassword.css'
import { useNavigate } from "react-router-dom";
import { useState } from 'react';
import auth from '../../Connections/firebase';
import { sendPasswordResetEmail } from 'firebase/auth';

function ForgotPassword() {
    const navigate = useNavigate();

    const [reset_email, set_reset_email] = useState("")

    const sendResetEmail = () => {
        if (reset_email && reset_email !== undefined && typeof reset_email === 'string' && reset_email.trim().length > 0) {
            set_reset_email(reset_email.trim())
            sendPasswordResetEmail(auth, reset_email)
                        .then((res) => {
                            alert('A mail has been shared with you to reset your Password')
                            set_reset_email('')
                            navigate("/")
                        })
                        .catch((err) => {
                            alert('Invalid Email Address')
                        })
        } else {
            alert("Email is required")
        }
    }

    return (
        <section className='forgot_password_page_container'>
            <div className='signup_head'>
                <div className='signup_head_brand'>
                    <img onClick={() => navigate("/")} src='https://feed-posts.s3.amazonaws.com/blockerx-landing-page/assets/icon.png' alt='brand-logo' />
                </div>
                <div className='signup_head_btns'>
                    <button onClick={() => navigate("/signup")}>Signup</button>
                </div>
            </div>
            <div className='signup_body'>
                <div className='signup_body_area'>
                    <div className='signup_body_text'>
                        <h2>Reset your Password</h2>
                        <span>Enter your email Address here and you will recieve a mail for reseting Password</span>
                    </div>
                    <div className='signup_body_form'>
                        <input type='text' value={reset_email} onChange={(e) => { set_reset_email(e.target.value) }} placeholder='Enter your Email' />
                        <button onClick={sendResetEmail} id="signupNow">Reset Password</button>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ForgotPassword;