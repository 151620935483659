import { useState } from 'react'
import { useNavigate } from "react-router-dom";
import mixpanel from 'mixpanel-browser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightLong, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import './quizPage.css'
import load from '../SupportChat/loading-paper.gif'
import codi from '../SupportChat/codi.png'
import quizIcon from '../LandingPage/quiz_main_icon.png'
import introImg from '../LandingPage/quiz_intro.png'
import quizImage2 from '../LandingPage/quiz_page_2.png'
import quizImage3 from '../LandingPage/quiz_page_3.png'
import quizImage4 from '../LandingPage/quiz_page_4.png'
import quizImage5 from '../LandingPage/quiz_page_5.png'
import quizImage6 from '../LandingPage/quiz_page_6.png'
import quizImage7 from '../LandingPage/quiz_page_7.png'
import quizEndStar from '../LandingPage/quiz_premium_review.png'

mixpanel.init('d051baf39ffda3bf75a7b21ddff1eef4', { debug: false });

function QuizPage() {

    const navigate = useNavigate();
    const [currentQuestion, setCurrentQuestion] = useState(1)
    const [activeAnswer, setActiveAnswer] = useState(null)
    const [quizResult, setQuizResult] = useState(20)
    const [quizAlert, setQuizAlert] = useState(false)

    const submitAnswer = (questionNum) => {
        console.log({ quizResult })
        mixpanel.track('QuizQuestionInteraction', {
            [questionNum]: activeAnswer
        })
        if (activeAnswer) {
            setQuizAlert(false)
            if (questionNum === 1) {
                if (activeAnswer === 1) {
                    setQuizResult(quizResult + 15)
                } else if (activeAnswer === 2) {
                    setQuizResult(quizResult + 10)
                } else if (activeAnswer === 3) {
                    setQuizResult(quizResult + 5)
                }
            } else if (questionNum === 2) {
                if (activeAnswer === 1) {
                    setQuizResult(quizResult + 10)
                } else if (activeAnswer === 2) {
                    setQuizResult(quizResult + 7)
                } else if (activeAnswer === 3) {
                    setQuizResult(quizResult + 5)
                } else {
                    setQuizResult(quizResult + 3)
                }
            } else if (questionNum === 4) {
                if (activeAnswer === 1) {
                    setQuizResult(quizResult + 3)
                } else if (activeAnswer === 2) {
                    setQuizResult(quizResult + 5)
                } else if (activeAnswer === 3) {
                    setQuizResult(quizResult + 7)
                } else {
                    setQuizResult(quizResult + 15)
                }
            } else if (questionNum === 5) {
                if (activeAnswer === 1) {
                    setQuizResult(quizResult + 10)
                } else if (activeAnswer === 2) {
                    setQuizResult(quizResult + 7)
                } else if (activeAnswer === 3) {
                    setQuizResult(quizResult + 7)
                } else {
                    setQuizResult(quizResult + 5)
                }
            } else if (questionNum === 6) {
                if (activeAnswer === 1) {
                    setQuizResult(quizResult + 10)
                } else if (activeAnswer === 2) {
                    setQuizResult(quizResult + 15)
                } else if (activeAnswer === 3) {
                    setQuizResult(quizResult + 5)
                } else {
                    setQuizResult(quizResult + 5)
                }
            } else if (questionNum === 7) {
                if (activeAnswer === 1) {
                    setQuizResult(quizResult + 14)
                } else {
                    setQuizResult(quizResult + 0)
                }
            }
            setActiveAnswer(null)
            setCurrentQuestion(questionNum + 1)
            if (questionNum === 7) {
                const timer = setTimeout(() => {
                    setCurrentQuestion(9)
                }, 1500);
                return () => clearTimeout(timer);
            }
        } else {
            setQuizAlert(true)
        }
    }

    const openAnnualFreeTrailForApp = () => {
        window.open('https://buy.stripe.com/dR603a3YZ5r7cEw7tA', '_blank');
    }

    return (
        <section id='home_quiz_area'>
            {currentQuestion === 1 ? (
                <div style={{ width: '100%', height: '100%' }} className='quiz_container' id='quiz_question_1'>
                    <div className='quiz_sidebar'>
                        <div className='quiz_sidebar_heading'>
                            <img src={codi} alt='codi' />
                            <h1>Hi! I am Codi!</h1>
                        </div>
                        <p>
                            I am your personal AI Friend, and I will help you embark on the journey
                            <strong> quit porn & distraction and become the best version of yourself.</strong>
                        </p>
                        <img className='codi_says' src={introImg} alt='codi_says' />
                    </div>
                    <div className='quiz_questionare'>
                        <div className='close_area'>
                            <FontAwesomeIcon onClick={() => { navigate(-1) }} className='close_btn_quiz' icon={faCircleXmark} color='#013945' />
                        </div>
                        <div className='quiz_question_box'>
                            <h2>Do you fantasize about porn during sex?</h2>
                        </div>
                        <div className='quiz_option_box'>
                            <div className={activeAnswer === 1 ? 'quiz_option_single active_option' : 'quiz_option_single'} onClick={() => { setActiveAnswer(1) }}>
                                <span className='quiz_option_single_num'>
                                    <p>1</p>
                                </span>
                                <span className='quiz_option_single_tag'>Very Frequently</span>
                            </div>
                            <div className={activeAnswer === 2 ? 'quiz_option_single active_option' : 'quiz_option_single'} onClick={() => { setActiveAnswer(2) }}>
                                <span className='quiz_option_single_num'>
                                    <p>2</p>
                                </span>
                                <span className='quiz_option_single_tag'>Frequently</span>
                            </div>
                            <div className={activeAnswer === 3 ? 'quiz_option_single active_option' : 'quiz_option_single'} onClick={() => { setActiveAnswer(3) }}>
                                <span className='quiz_option_single_num'>
                                    <p>3</p>
                                </span>
                                <span className='quiz_option_single_tag'>Occasionally</span>
                            </div>
                            <div className={activeAnswer === 4 ? 'quiz_option_single active_option' : 'quiz_option_single'} onClick={() => { setActiveAnswer(4) }}>
                                <span className='quiz_option_single_num'>
                                    <p>4</p>
                                </span>
                                <span className='quiz_option_single_tag'>Rarely or Never</span>
                            </div>
                        </div>
                        {quizAlert ? (
                            <span className='red_alert'>
                                *Please select one option to Continue
                            </span>
                        ) : (
                            <></>
                        )}
                        <div className='quiz_cta_btn'>
                            <button onClick={() => { submitAnswer(1) }}>Next</button>
                        </div>
                    </div>
                </div>
            ) : currentQuestion === 2 ? (
                <div style={{ width: '100%', height: '100%' }} className='quiz_container' id='quiz_question_2'>
                    <div className='quiz_sidebar'>
                        <p>
                            Blocker X user noted a significant <strong>reduction of the need for porn</strong> within 3 month of using this app
                        </p>
                        <img style={{ width: '70%' }} className='codi_says' src={quizImage2} alt='codi_says' />
                    </div>
                    <div className='quiz_questionare'>
                        <div className='close_area'>
                            <FontAwesomeIcon onClick={() => { navigate(-1) }} className='close_btn_quiz' icon={faCircleXmark} color='#013945' />
                        </div>
                        <div className='quiz_question_box'>
                            <h2>What age did you start viewing Porn?</h2>
                        </div>
                        <div className='quiz_option_box'>
                            <div className={activeAnswer === 1 ? 'quiz_option_single active_option' : 'quiz_option_single'} onClick={() => { setActiveAnswer(1) }}>
                                <span className='quiz_option_single_num'>
                                    <p>1</p>
                                </span>
                                <span className='quiz_option_single_tag'>12 or younger</span>
                            </div>
                            <div className={activeAnswer === 2 ? 'quiz_option_single active_option' : 'quiz_option_single'} onClick={() => { setActiveAnswer(2) }}>
                                <span className='quiz_option_single_num'>
                                    <p>2</p>
                                </span>
                                <span className='quiz_option_single_tag'>13 to 16</span>
                            </div>
                            <div className={activeAnswer === 3 ? 'quiz_option_single active_option' : 'quiz_option_single'} onClick={() => { setActiveAnswer(3) }}>
                                <span className='quiz_option_single_num'>
                                    <p>3</p>
                                </span>
                                <span className='quiz_option_single_tag'>17 to 24</span>
                            </div>
                            <div className={activeAnswer === 4 ? 'quiz_option_single active_option' : 'quiz_option_single'} onClick={() => { setActiveAnswer(4) }}>
                                <span className='quiz_option_single_num'>
                                    <p>4</p>
                                </span>
                                <span className='quiz_option_single_tag'>25 and older</span>
                            </div>
                        </div>
                        {quizAlert ? (
                            <span className='red_alert'>
                                *Please select one option to Continue
                            </span>
                        ) : (
                            <></>
                        )}
                        <div className='quiz_cta_btn'>
                            <button onClick={() => { submitAnswer(2) }}>Next</button>
                        </div>
                    </div>
                </div>
            ) : currentQuestion === 3 ? (
                <div style={{ width: '100%', height: '100%' }} className='quiz_container' id='quiz_question_3'>
                    <div className='quiz_sidebar'>
                        <p>
                            <strong>More than 50% of porn addicts</strong> have reported loss of interest in real sex and a decrease on libido
                        </p>
                        <img style={{ width: '70%' }} className='codi_says' src={quizImage3} alt='codi_says' />
                    </div>
                    <div className='quiz_questionare'>
                        <div className='close_area'>
                            <FontAwesomeIcon onClick={() => { navigate(-1) }} className='close_btn_quiz' icon={faCircleXmark} color='#013945' />
                        </div>
                        <div className='quiz_question_box'>
                            <h2>What is your Gender?</h2>
                        </div>
                        <div className='quiz_option_box'>
                            <div className={activeAnswer === 1 ? 'quiz_option_single active_option' : 'quiz_option_single'} onClick={() => { setActiveAnswer(1) }}>
                                <span className='quiz_option_single_num'>
                                    <p>1</p>
                                </span>
                                <span className='quiz_option_single_tag'>Male</span>
                            </div>
                            <div className={activeAnswer === 2 ? 'quiz_option_single active_option' : 'quiz_option_single'} onClick={() => { setActiveAnswer(2) }}>
                                <span className='quiz_option_single_num'>
                                    <p>2</p>
                                </span>
                                <span className='quiz_option_single_tag'>Female</span>
                            </div>
                            <div className={activeAnswer === 3 ? 'quiz_option_single active_option' : 'quiz_option_single'} onClick={() => { setActiveAnswer(3) }}>
                                <span className='quiz_option_single_num'>
                                    <p>3</p>
                                </span>
                                <span className='quiz_option_single_tag'>Others</span>
                            </div>
                            <div className={activeAnswer === 4 ? 'quiz_option_single active_option' : 'quiz_option_single'} onClick={() => { setActiveAnswer(4) }}>
                                <span className='quiz_option_single_num'>
                                    <p>4</p>
                                </span>
                                <span className='quiz_option_single_tag'>Rather not say</span>
                            </div>
                        </div>
                        {quizAlert ? (
                            <span className='red_alert'>
                                *Please select one option to Continue
                            </span>
                        ) : (
                            <></>
                        )}
                        <div className='quiz_cta_btn'>
                            <button onClick={() => { submitAnswer(3) }}>Next</button>
                        </div>
                    </div>
                </div>
            ) : currentQuestion === 4 ? (
                <div style={{ width: '100%', height: '100%' }} className='quiz_container' id='quiz_question_4'>
                    <div className='quiz_sidebar'>
                        <p>
                            Watching porn releases dopamine in the brain. This chemical makes you feel good, <strong>it’s why you feel pleasure when viewing porn </strong>.
                        </p>
                        <img style={{ width: '70%' }} className='codi_says' src={quizImage4} alt='codi_says' />
                    </div>
                    <div className='quiz_questionare'>
                        <div className='close_area'>
                            <FontAwesomeIcon onClick={() => { navigate(-1) }} className='close_btn_quiz' icon={faCircleXmark} color='#013945' />
                        </div>
                        <div className='quiz_question_box'>
                            <h2>On a weekly basis, how many hours do you spend on consuming Porn?</h2>
                        </div>
                        <div className='quiz_option_box'>
                            <div className={activeAnswer === 1 ? 'quiz_option_single active_option' : 'quiz_option_single'} onClick={() => { setActiveAnswer(1) }}>
                                <span className='quiz_option_single_num'>
                                    <p>1</p>
                                </span>
                                <span className='quiz_option_single_tag'>Less than 1 hour</span>
                            </div>
                            <div className={activeAnswer === 2 ? 'quiz_option_single active_option' : 'quiz_option_single'} onClick={() => { setActiveAnswer(2) }}>
                                <span className='quiz_option_single_num'>
                                    <p>2</p>
                                </span>
                                <span className='quiz_option_single_tag'>1 - 3 hours</span>
                            </div>
                            <div className={activeAnswer === 3 ? 'quiz_option_single active_option' : 'quiz_option_single'} onClick={() => { setActiveAnswer(3) }}>
                                <span className='quiz_option_single_num'>
                                    <p>3</p>
                                </span>
                                <span className='quiz_option_single_tag'>4 - 6 hours</span>
                            </div>
                            <div className={activeAnswer === 4 ? 'quiz_option_single active_option' : 'quiz_option_single'} onClick={() => { setActiveAnswer(4) }}>
                                <span className='quiz_option_single_num'>
                                    <p>4</p>
                                </span>
                                <span className='quiz_option_single_tag'>More than 7 hours</span>
                            </div>
                        </div>
                        {quizAlert ? (
                            <span className='red_alert'>
                                *Please select one option to Continue
                            </span>
                        ) : (
                            <></>
                        )}
                        <div className='quiz_cta_btn'>
                            <button onClick={() => { submitAnswer(4) }}>Next</button>
                        </div>
                    </div>
                </div>
            ) : currentQuestion === 5 ? (
                <div style={{ width: '100%', height: '100%' }} className='quiz_container' id='quiz_question_5'>
                    <div className='quiz_sidebar'>
                        <img className='codi_say5' src={quizImage5} alt='codi_says' />
                    </div>
                    <div className='quiz_questionare'>
                        <div className='close_area'>
                            <FontAwesomeIcon onClick={() => { navigate(-1) }} className='close_btn_quiz' icon={faCircleXmark} color='#013945' />
                        </div>
                        <div className='quiz_question_box'>
                            <h2>What trigger usually leads you to consume Porn?</h2>
                        </div>
                        <div className='quiz_option_box'>
                            <div className={activeAnswer === 1 ? 'quiz_option_single active_option' : 'quiz_option_single'} onClick={() => { setActiveAnswer(1) }}>
                                <span className='quiz_option_single_num'>
                                    <p>1</p>
                                </span>
                                <span className='quiz_option_single_tag'>Stress</span>
                            </div>
                            <div className={activeAnswer === 2 ? 'quiz_option_single active_option' : 'quiz_option_single'} onClick={() => { setActiveAnswer(2) }}>
                                <span className='quiz_option_single_num'>
                                    <p>2</p>
                                </span>
                                <span className='quiz_option_single_tag'>Loneliness</span>
                            </div>
                            <div className={activeAnswer === 3 ? 'quiz_option_single active_option' : 'quiz_option_single'} onClick={() => { setActiveAnswer(3) }}>
                                <span className='quiz_option_single_num'>
                                    <p>3</p>
                                </span>
                                <span className='quiz_option_single_tag'>Peer Influence</span>
                            </div>
                            <div className={activeAnswer === 4 ? 'quiz_option_single active_option' : 'quiz_option_single'} onClick={() => { setActiveAnswer(4) }}>
                                <span className='quiz_option_single_num'>
                                    <p>4</p>
                                </span>
                                <span className='quiz_option_single_tag'>Others</span>
                            </div>
                        </div>
                        {quizAlert ? (
                            <span className='red_alert'>
                                *Please select one option to Continue
                            </span>
                        ) : (
                            <></>
                        )}
                        <div className='quiz_cta_btn'>
                            <button onClick={() => { submitAnswer(5) }}>Next</button>
                        </div>
                    </div>
                </div>
            ) : currentQuestion === 6 ? (
                <div style={{ width: '100%', height: '100%' }} className='quiz_container' id='quiz_question_6'>
                    <div className='quiz_sidebar'>
                        <p>
                            <strong>Get tons of resources to know more about your problem.</strong> Complete video courses, read e-books and more.
                        </p>
                        <img style={{ width: '70%' }} className='codi_says' src={quizImage6} alt='codi_says' />
                    </div>
                    <div className='quiz_questionare'>
                        <div className='close_area'>
                            <FontAwesomeIcon onClick={() => { navigate(-1) }} className='close_btn_quiz' icon={faCircleXmark} color='#013945' />
                        </div>
                        <div className='quiz_question_box'>
                            <h2>Which of these majorly affects due to consuming Porn?</h2>
                        </div>
                        <div className='quiz_option_box'>
                            <div className={activeAnswer === 1 ? 'quiz_option_single active_option' : 'quiz_option_single'} onClick={() => { setActiveAnswer(1) }}>
                                <span className='quiz_option_single_num'>
                                    <p>1</p>
                                </span>
                                <span className='quiz_option_single_tag'>Emotional /Mental Health</span>
                            </div>
                            <div className={activeAnswer === 2 ? 'quiz_option_single active_option' : 'quiz_option_single'} onClick={() => { setActiveAnswer(2) }}>
                                <span className='quiz_option_single_num'>
                                    <p>2</p>
                                </span>
                                <span className='quiz_option_single_tag'>Sexual Health</span>
                            </div>
                            <div className={activeAnswer === 3 ? 'quiz_option_single active_option' : 'quiz_option_single'} onClick={() => { setActiveAnswer(3) }}>
                                <span className='quiz_option_single_num'>
                                    <p>3</p>
                                </span>
                                <span className='quiz_option_single_tag'>Work and Social Life</span>
                            </div>
                            <div className={activeAnswer === 4 ? 'quiz_option_single active_option' : 'quiz_option_single'} onClick={() => { setActiveAnswer(4) }}>
                                <span className='quiz_option_single_num'>
                                    <p>4</p>
                                </span>
                                <span className='quiz_option_single_tag'>Financial Health</span>
                            </div>
                        </div>
                        {quizAlert ? (
                            <span className='red_alert'>
                                *Please select one option to Continue
                            </span>
                        ) : (
                            <></>
                        )}
                        <div className='quiz_cta_btn'>
                            <button onClick={() => { submitAnswer(6) }}>Next</button>
                        </div>
                    </div>
                </div>
            ) : currentQuestion === 7 ? (
                <div style={{ width: '100%', height: '100%' }} className='quiz_container' id='quiz_question_7'>
                    <div className='quiz_sidebar'>
                        <p>
                            Join forces with a trusted partner and embark on a journey toward a <strong>safer and empowering online experience</strong> with BlockerX
                        </p>
                        <img style={{ width: '70%' }} className='codi_says' src={quizImage7} alt='codi_says' />
                    </div>
                    <div className='quiz_questionare'>
                        <div className='close_area'>
                            <FontAwesomeIcon onClick={() => { navigate(-1) }} className='close_btn_quiz' icon={faCircleXmark} color='#013945' />
                        </div>
                        <div className='quiz_question_box'>
                            <h2>Have you ever Spent money on Purchasing Porn Website Subscription</h2>
                        </div>
                        <div className='quiz_option_box'>
                            <div className={activeAnswer === 1 ? 'quiz_option_single active_option' : 'quiz_option_single'} onClick={() => { setActiveAnswer(1) }}>
                                <span className='quiz_option_single_num'>
                                    <p>1</p>
                                </span>
                                <span className='quiz_option_single_tag'>Yes</span>
                            </div>
                            <div className={activeAnswer === 2 ? 'quiz_option_single active_option' : 'quiz_option_single'} onClick={() => { setActiveAnswer(2) }}>
                                <span className='quiz_option_single_num'>
                                    <p>2</p>
                                </span>
                                <span className='quiz_option_single_tag'>No</span>
                            </div>
                        </div>
                        {quizAlert ? (
                            <span className='red_alert'>
                                *Please select one option to Continue
                            </span>
                        ) : (
                            <></>
                        )}
                        {quizAlert ? (
                            <span className='red_alert'>
                                *Please select one option to Continue
                            </span>
                        ) : (
                            <></>
                        )}
                        <div className='quiz_cta_btn'>
                            <button onClick={() => { submitAnswer(7) }}>Next</button>
                        </div>
                    </div>
                </div>
            ) : currentQuestion === 8 ? (
                <div style={{ width: '100%', height: '100%' }} className='quiz_container' id='quiz_question_8'>
                    <div className='quiz_calculating'>
                        <h2>Quiz Complete</h2>
                        <p>Calculating Results...</p>
                        <img src={load} alt='loading' />
                    </div>
                </div>
            ) : currentQuestion === 9 ? (
                <div style={{ width: '100%', height: '100%' }} className='quiz_container' id='quiz_question_9'>
                    <div className='quiz_sidebar' id='sidebar_result'>
                        <div className='close_area'>
                            <FontAwesomeIcon onClick={() => { navigate(-1) }} className='close_btn_quiz' icon={faCircleXmark} color='#013945' />
                        </div>
                        <div className='quiz_sidebar_heading' id='sidebar_head'>
                            <img src={codi} alt='codi' />
                        </div>
                        <p>
                            <strong>
                                Some not so good news :
                            </strong>
                        </p>
                        {quizResult > 70 ? (
                            <p className='result_heading'>Your are Severely addicted to internet porn*</p>
                        ) : quizResult > 30 ? (
                            <p className='result_heading'>Your are more Addicted to internet porn than an average person*</p>
                        ) : (
                            <p className='result_heading'>Your are not Addicted to porn*</p>
                        )}
                        <p id='sidebar_message'>
                            But the good news is, <strong>you are now in the right place</strong>
                        </p>
                        <button id='result_btn_large' onClick={() => {
                            mixpanel.track('QuizPage', {
                                'click': 'continue_on_result'
                            })
                            setCurrentQuestion(10)
                        }} className='result_continue'>Continue</button>
                        <span className='dopamine_info'>Dopamine baseline is {quizResult - 27}% above estimated average*</span>
                        <span className='estimate_info'>*Estimate only</span>
                    </div>
                    <div id='embed_quiz_result' className='quiz_result'>
                        <div className='quiz_result_inner'>
                            <div className='quiz_result_avg' style={{ height: '27%' }}>
                                <h1>27%</h1>
                                <span>Average</span>
                            </div>
                            <div className='quiz_result_user' style={{ height: `${quizResult}%` }}>
                                <h1>{quizResult}%</h1>
                                <span>You</span>
                            </div>
                        </div>
                        <button id='result_btn_small' onClick={() => {
                            mixpanel.track('QuizPage', {
                                'click': 'continue_on_result'
                            })
                            setCurrentQuestion(10)
                        }} className='result_continue'>Continue</button>
                    </div>
                </div>
            ) : (
                <div style={{ width: '100%', height: '100%' }} className='quiz_container' id='quiz_question_10'>
                    <div className='quiz_sidebar'>
                        <div className='close_area'>
                            <FontAwesomeIcon onClick={() => { navigate(-1) }} className='close_btn_quiz' icon={faCircleXmark} color='#013945' />
                        </div>
                        <h1 className='blockerx_perks_heading'>Get Blockerx Premium now!</h1>
                        <div className='blockerx_single_benifit'>
                            <span>1</span>
                            <p>We will help you maximise your productivity by <strong>blocking all porn and distracting sites.</strong></p>
                        </div>
                        <div className='blockerx_single_benifit'>
                            <span>2</span>
                            <p>An <strong>Accountability Partner</strong> to make sure you don’t relapse.</p>
                        </div>
                        <div className='blockerx_single_benifit'>
                            <span>3</span>
                            <p>A <strong>Community</strong> of like minded people to share your journey and stay motivated.</p>
                        </div>
                        <div className='blockerx_single_benifit'>
                            <span>4</span>
                            <p>An investment in yourself to become the <strong>Best Version of You.</strong></p>
                        </div>
                    </div>
                    <div className='quiz_premium_end'>
                        <div className='close_area'>
                            <FontAwesomeIcon onClick={() => { navigate(-1) }} className='close_btn_quiz' icon={faCircleXmark} color='#013945' />
                        </div>
                        <div className='codi_area'>
                            <img src={codi} alt='codi' />
                        </div>
                        <h1>Start your Journey Today</h1>
                        <div className='user_review'>
                            <img src={quizEndStar} alt='5 stars' />
                            <p>“A fantastic app. Helped me overcome my addiction in 2 months! I’ve never been happier.”</p>
                            <span>-Ian Thige</span>
                        </div>
                        <div className='premium_card'>
                            <div className='premium_offer_area'>
                                <span>54% Discount</span>
                            </div>
                            <div className='card_content'>
                                <div className='card_plan_area'>
                                    <h3>12 Months Plan</h3>
                                    <p>Renews automatically, unless cancelled</p>
                                    <p>Yearly payment of <strike>$239.64</strike><strong> $109.95</strong></p>
                                    <span>Charged only after trial ends</span>
                                </div>
                                <div className='card_price_area'>
                                    <h2>$109.95</h2>
                                    <h4><strike>$239.64</strike></h4>
                                    <p>*Cancel Anytime</p>
                                </div>
                            </div>
                        </div>
                        <button onClick={() => {
                            mixpanel.track('QuizPage', {
                                'click': 'get_free_trail'
                            })
                            openAnnualFreeTrailForApp()
                        }}>Start Today - with 1 day free trial <FontAwesomeIcon icon={faArrowRightLong} /></button>
                    </div>
                </div>
            )}
        </section>
    )
}

export default QuizPage