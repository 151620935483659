import { useParams, useNavigate } from 'react-router-dom';
import './automation.css'
import { useEffect, useState } from 'react';
import load from '../SupportChat/loading-paper.gif';
import axios from 'axios';
import auth, { provider } from '../../Connections/firebase';
import { signInWithPopup } from 'firebase/auth';

const reasons = {
    "streak": {
        "reset": "You wish to reset your streak",
        "continue": "Your streak is stuck, and you wish to fix it",
        "restore": "Restore your previous streak"
    },
    "premium": {
        "refund": "Check for getting Refund/ Cancel BlockerX Premium",
        "activate_google": "Activate Purchase through Google Play for BlockerX Premium"
    },
    "partner": {
        "remove": "Accountabilty partner is not responding"
    },
    "apk": {
        "updates": "Stop getting BlockerX App updates"
    },
    "switch": {
        "forceUninstall": "Turn of my Prevent Uninstall Switch"
    }
}

function AutomationAuthPage() {

    const { category, subcategory } = useParams()
    console.log({ category, subcategory })

    const navigate = useNavigate()

    const [loading, setLoading] = useState(true)
    const [new_user_email, set_new_user_email] = useState("")
    const [new_user_password1, set_new_user_password1] = useState("")

    function loginUserUsingCredentials(email, password) {
        return new Promise((resolve, reject) => {
            axios.post('https://accounts.blockerx.net/api/login/refund', {
                provider: false,
                email,
                password
            })
                .then((response) => {
                    try {
                        if (response.data.status === 200) {
                            resolve(response.data.data.user)
                        } else {
                            resolve(null)
                        }
                    } catch {
                        resolve(null)
                    }
                })
                .catch((error2) => {
                    resolve(null)
                });
        })
    }

    const loginWithGoogle = () => {
        signInWithPopup(auth, provider)
            .then(async (result) => {
                console.log({ result })
                navigate(`/chatbot/automation/${category}/${subcategory}/${result.user.uid}/${result.user.email}`)
            })
            .catch((err) => {
                alert('Something went wrong')
            })
    }

    const logInNow = async () => {
        const tempEmail = new_user_email.trim()
        const tempPassword = new_user_password1.trim()

        if (tempEmail && tempEmail.length > 0) {
            if (tempPassword && tempPassword.length > 0) {
                const RESULT = await loginUserUsingCredentials(tempEmail, tempPassword)
                set_new_user_email('')
                set_new_user_password1('')
                if (RESULT !== null && RESULT.uid) {
                    console.log({ RESULT })
                    navigate(`/chatbot/automation/${category}/${subcategory}/${RESULT.uid}/${tempEmail}`)
                } else {
                    alert('Credentials added are invalid')
                }
            } else {
                alert('Please enter a valid Password')
            }
        } else {
            alert('Please enter a valid Email')
        }
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false)
        }, 500);
        return () => clearTimeout(timer);
    }, []);

    if (loading) {
        return (
            <section id='chatbot_loading'>
                <img src={load} alt='loading' />
            </section>
        )
    } else {
        return (
            <section id='customize_login_area'>
                <div className='custom_login_head'>
                    <img src='https://feed-posts.s3.amazonaws.com/blockerx-landing-page/assets/icon.png' alt='brand-logo' />
                </div>
                <div className='custom_login_body'>
                    <div className='custom_login_body_area'>
                        <div className='custom_login_body_text'>
                            <h2>Verify Action</h2>
                            <span><strong>Reason: </strong>{reasons[category][subcategory]}</span>
                        </div>
                        <div className="google_area">
                            <button onClick={loginWithGoogle} id="custom_auth_google">
                                Signin with  <img width="25px" src="https://feed-posts.s3.amazonaws.com/blockerx-landing-page/assets/googleIcon.png" alt="google_icon" />
                            </button>
                        </div>
                        <div className="partition">
                            <span>or</span>
                        </div>
                        <div className='signup_body_form'>
                            <input type='text' value={new_user_email} onChange={(e) => { set_new_user_email(e.target.value) }} placeholder='Enter your Email' />
                            <input type="password" value={new_user_password1} onChange={(e) => { set_new_user_password1(e.target.value) }} placeholder="Enter Password" />
                            <button onClick={logInNow} id="custom_login">Login</button>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default AutomationAuthPage;