import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
const dtoxFirebaseConfig = {
    apiKey: "AIzaSyD5JgcCtfCvn7Uv88t9nVM4sLNpStYOWW0",
    authDomain: "dtox-22baa.firebaseapp.com",
    projectId: "dtox-22baa",
    storageBucket: "dtox-22baa.appspot.com",
    messagingSenderId: "1007122937868",
    appId: "1:1007122937868:web:02357d3f389a1204cbd629",
    measurementId: "G-9PJ7ZZNXRG",
};

// Initialize Firebase
const dtoxApp = initializeApp(dtoxFirebaseConfig, "third");
const dtoxAuth = getAuth(dtoxApp);
const dtoxProvider = new GoogleAuthProvider();

export default dtoxAuth;

export { dtoxProvider };
