import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from 'firebase/auth'
const contentBlockerFirebaseConfig = {
  apiKey: "AIzaSyCX-Fs7JDMC2nE07dZCS82bxBvzV93nh0E",
  authDomain: "content-blocker-e772d.firebaseapp.com",
  projectId: "content-blocker-e772d",
  storageBucket: "content-blocker-e772d.firebasestorage.app",
  messagingSenderId: "777395875567",
  appId: "1:777395875567:web:6ce28d46056a9f2f94b51a",
  measurementId: "G-QL9JEGX6G7"
};

// Initialize Firebase
const contentBlockerApp = initializeApp(contentBlockerFirebaseConfig, "tenth");
const contentBlockerAuth = getAuth(contentBlockerApp);
const contentBlockerProvider = new GoogleAuthProvider();

export default contentBlockerAuth;

export { contentBlockerProvider };