import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from 'firebase/auth'
const zenzeFirebaseConfig = {
    apiKey: "AIzaSyDvviVwCFCgxFt1HpeVYhSM-wFgC-lA_ic",
    authDomain: "zenze-11711.firebaseapp.com",
    projectId: "zenze-11711",
    storageBucket: "zenze-11711.appspot.com",
    messagingSenderId: "711686263867",
    appId: "1:711686263867:web:9a31e8f00ce1aec5d3781e",
    measurementId: "G-8RXB0RDRZB"
};

// Initialize Firebase
const zenzeApp = initializeApp(zenzeFirebaseConfig, "secondary");
const zenzeAuth = getAuth(zenzeApp);
const zenzeProvider = new GoogleAuthProvider();

export default zenzeAuth;

export { zenzeProvider };