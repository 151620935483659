import './downloadAPK.css';
import childAppIcon from './childApp.png'
import { useEffect, useState } from 'react';

function ChildAppAPK() {

    const [openPopup, setOpenPopup] = useState(true)
    const [gotItBtnStatus, setGotItBtnStatus] = useState(false)
    const [timer, setTimer] = useState(5)

    useEffect(() => {
        setOpenPopup(true)
        let tempNum = 5
        const countDownInterval = setInterval(() => {
            if (tempNum <= 0) {
                setTimer(0)
                setGotItBtnStatus(true)
                clearInterval(countDownInterval)
            }
            // setTimer((timer) => timer - 1)
            tempNum = tempNum - 1
            setTimer(tempNum)
        }, 1000);
        window.open('https://blockerx-android-apk.s3.us-east-1.amazonaws.com/Parental_Control_Child.apk', '_self')
    }, [])

    if (openPopup) {
        return (
            <section className='child_app_popup_outer'>
                <div className='child_app_popup_inner'>
                    <img src={childAppIcon} alt='app_icon' />
                    <p>Downloading Child APK... Once the download is complete find the downloaded APK in your Downloads folder and Install the APK on your device.</p>
                    {gotItBtnStatus ? (
                        <button onClick={() => {
                            setOpenPopup(false)
                        }}>Got it</button>
                    ): (
                        <button disabled>Got it {timer}s</button>
                    )}
                </div>
            </section>
        )
    } else {
        return (
            <section className="child_app_container">
                <div className='child_app_icon_area'>
                    <img src={childAppIcon} alt='app_icon' />
                </div>
                <div className='child_app_steps_container'>
                    <div className='child_app_single_step'>
                        <h2>1. Download Child APK</h2>
                        <p>If the download doesn't start, please re-click the button. Once the download is complete read the next step.</p>
                        <button onClick={() => {
                            window.open('https://blockerx-android-apk.s3.us-east-1.amazonaws.com/Parental_Control_Child.apk', '_self')
                        }}>Retry Download</button>
                    </div>
                    <div className='child_app_single_step'>
                        <h2>2. Install and Open Child APK</h2>
                        <p>Find the the Downloaded APK file in your files manager and Click to install the application.</p>
                    </div>
                </div>
            </section>
        )
    }
}

export default ChildAppAPK;