import './chatbot.css';
import { useState, useEffect } from 'react';
import axios from 'axios';

function ChatbotPage() {

  const paramToken = new URLSearchParams(window.location.search).get('token')

  let initialList = [
    {
      _id: 0,
      type: 'user',
      content: `I'm having an urge to watch porn. How do I stop it?`
    },
    {
      _id: 1,
      type: 'bot',
      content: `It's normal to have urges, and it can be difficult to control them. One way to help stop the urge to watch porn is to distract yourself with something else. For example, you could go for a walk, call a friend, or do some exercise.`
    }
  ]

  const [isloading, setIsloading] = useState(true)
  const [userValid, setUserValid] = useState(false)
  const [userInvalid, setUserInvalid] = useState(false)
  const [uid, setUid] = useState('')
  const [chattingList, setChattingList] = useState(initialList)
  const [inputQuery, setInputQuery] = useState('')
  const [isTyping, setIsTyping] = useState(false)

  useEffect(() => {
    console.log(' 1: ', paramToken)
    tokenValidation(paramToken)
  }, []);

  const sendQuery = () => {
    setInputQuery(inputQuery.trim())
    if (inputQuery !== '') {
      queryHandler(inputQuery)
    } else {
      alert('Enter a valid Question')
    }
  }

  const sendSuggestion1 = () => {
    queryHandler(`How are you doing ?`)
  }

  const sendSuggestion2 = () => {
    queryHandler(`Is Porn Addiction a serious issue ?`)
  }

  const sendSuggestion3 = () => {
    queryHandler(`How should I overcome from Porn Addiction ?`)
  }

  const queryHandler = (query) => {
    initialList = chattingList
    initialList.push({
      _id: new Date().getTime(),
      type: 'user',
      content: query
    })
    setChattingList(initialList)
    setInputQuery('')
    setIsTyping(true)
    const element = document.querySelector('.empty_space');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    axios.post('https://accounts.blockerx.net/api/chatGpt', {
      uid,
      query
    })
      .then((response) => {
        if (response.data.status === 'success') {
          const result = response.data.result
          setIsTyping(false)
          initialList.push({
            _id: new Date().getTime(),
            type: 'bot',
            content: result
          })
          setChattingList(initialList)
          const element = document.querySelector('.empty_space');
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        } else {
          setIsTyping(false)
        }
      })
      .catch((error) => {
        setIsTyping(false)
      })
  }

  function isValidToken(paramToken) {
    return new Promise((resolve, reject) => {
      axios.post('https://accounts.blockerx.net/api/verify/userToken', {
        token: paramToken
      })
        .then((response) => {
          console.log(' 2: ', response.data)
          if (response.data.status === 'success' && response.data.data.data) {
            setUid(response.data.data.message)
            resolve({
              status: true
            })
          } else {
            resolve({
              status: false
            })
          }
        })
        .catch((error2) => {
          console.log(' Error 2: ', error2)
          resolve({
            status: false
          })
        })
    })
  }

  async function tokenValidation(token) {
    const tokenStatus = await isValidToken(token)
    console.log(' 3: ', tokenStatus)
    if (token !== undefined && token !== null && typeof token === 'string' && token.length > 0 && tokenStatus.status) {
      setIsloading(false)
      setUserValid(true)
      setUserInvalid(false)
    } else {
      setIsloading(false)
      setUserValid(false)
      setUserInvalid(true)
    }
  }

  if (isloading) {
    return (
      <section id="loading_screen">
        <img src="https://feed-posts.s3.amazonaws.com/blocker-chat/assets/loading.gif" alt="" />
      </section>
    )
  }

  if (userValid) {
    return (
      <section id="user_authenticated">
        <div id="chat_list" className="chat_area">
          {chattingList.map((one_chat) => {
            if (one_chat.type === 'user') {
              return (
                <div key={one_chat._id} className="single_chat_area user_chat_area">
                  <div className="single_chat userChat">{one_chat.content}</div>
                </div>
              )
            } else {
              return (
                <div key={one_chat._id} className="single_chat_area bot_chat_area">
                  <div className="single_chat botChat">{one_chat.content}</div>
                </div>
              )
            }
          })}
        </div>
        {(() => {
          if (isTyping) {
            return (
              <div className="typing_area" id="typing_state">
                <img className="typing_img" src="https://feed-posts.s3.amazonaws.com/blocker-chat/assets/typing_animation.gif" alt="" />
              </div>
            )
          }
        })()}
        <div className="empty_space"></div>
        <div className="question_area">
          <div className="suggestions_area">
            <button id="suggestion_1" className="suggestions" onClick={sendSuggestion1}>How are you doing ?</button>
            <button id="suggestion_2" className="suggestions" onClick={sendSuggestion2}>Is Porn Addiction a serious issue ?</button>
            <button id="suggestion_3" className="suggestions" onClick={sendSuggestion3}>How should I overcome from Porn Addiction ?</button>
          </div>
          <div className="ask_question">
            <input id="user_question" value={inputQuery} onChange={(e) => { setInputQuery(e.target.value) }} type="text" placeholder="Ask a question .." />
            <button id="send_question" onClick={sendQuery}><img src="https://feed-posts.s3.amazonaws.com/blocker-chat/assets/Send.png" alt="" /></button>
          </div>
        </div>
      </section>
    )
  }

  if (userInvalid) {
    return (
      <section id="invalid_user">
        <div className="error_heading">
          <h1>You seem to be Lost</h1>
        </div>
        <div className="error_img">
          <img src="https://feed-posts.s3.amazonaws.com/blocker-chat/assets/lostPageImg.gif" alt="" />
        </div>
      </section>
    )
  }
}

export default ChatbotPage;