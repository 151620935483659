import './achievementCard.css';

function AchievementCard (props) {
    const progressWidth = Math.floor((props.score/ props.target)*100)
    return (
        <div className='card_area'>
            <div className='card_icon_area' style={{ background: props.iconBg }}>
                <div className='card_icon'>
                    <img src={props.icon} alt='card' />
                </div>
                <div className='card_level'>
                    <h3>Level {props.currentLevel}</h3>
                </div>
            </div>
            <div className='card_title'>
                <h2>{props.title}</h2>
            </div>
            <span className='card_description'>{props.description}</span>
            <div className='card_progress_area'>
                <div className='card_progress_bar'>
                    <div className='card_progress_inner' style={{width: `${progressWidth}%`}}>
                    </div>
                </div>
                <div className='card_progress_num'>
                    <span>{props.score}/{props.target}</span>
                </div>
            </div>
        </div>
    )
}

export default AchievementCard