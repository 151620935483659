import './downloadAPK.css';
import arrow from './arrow.png';
import { useEffect } from 'react';

function DownloadMDM () {

    const fileUrl = 'https://blockerx-android-apk.s3.us-east-1.amazonaws.com/hungry2growsoftwareprivatelimited.mobileconfig';

    useEffect(() => {
        window.open(fileUrl, '_self')
    }, [])

    return (
        <section className="download_mdm_page">
            <div className='download_mdm_steps'>
                <div className='mdm_arrow'>
                    <img src={arrow} alt=''/>
                </div>
                <div className='mdm_pointers'>
                    <div className='mdm_single_point'>
                        <span>1</span>
                        <p>When prompted, tap <strong>Allow</strong></p>
                    </div>
                    <div className='mdm_single_point'>
                        <span>2</span>
                        <p>Then tap <strong>Close</strong></p>
                    </div>
                    <div className='mdm_single_point'>
                        <span>3</span>
                        <p>Tap <strong>Done </strong>to return to Parental Control App</p>
                    </div>
                </div>
            </div>
            <div className='mdm_footer'>
                <button onClick={() => {
                    window.open(fileUrl, '_self')
                }}>Download the Profile again</button>
            </div>
        </section>
    )
}

export default DownloadMDM;