import { useEffect, useState } from 'react'
import './blockWindow.css'
import loading from './loading.gif'

function BlockedPage() {

    const paramsType = new URLSearchParams(window.location.search).get("type")
    const paramsDomain = new URLSearchParams(window.location.search).get("domain")
    const paramsReason = new URLSearchParams(window.location.search).get("reason")
    const [isLoading, setIsLoading] = useState(true)
    const [type, setType] = useState(null)
    const [domain, setDomain] = useState('')
    const [reason, setReason] = useState('')

    useEffect(() => {
        if (paramsType && paramsType.length) {
            setType(paramsType.trim())
        }
        if (paramsDomain && paramsDomain.length) {
            setDomain(paramsDomain.trim())
        }
        if (paramsReason && paramsReason.length) {
            setReason(paramsReason.trim())
        }
        setIsLoading(false)
    }, [])

    if (isLoading) {
        return (
            <section className='main_block_window'>
                <img src={loading} alt='loading....' width={80}/>
            </section>
        )
    } else {
        return (
            <section className='main_block_window'>
                <div className='upper_empty_space'>
    
                </div>
                <div className='blocked_content_area'>
                    <div className='blocked_by_logo'>
                        <img src="https://feed-posts.s3.amazonaws.com/blockerx-landing-page/assets/icon.png" alt="Logo" />
                    </div>
                    <div className='blocked_by_heading'>
                        <h2>{domain} is blocked by BlockerX</h2>
                    </div>
                    <div className='blocked_by_message'>
                        <p>
                            Take a break from Distractions. Manage your blocking from BlockerX app
                        </p>
                    </div>
                    <div className='blocked_by_actions'>
                        <button className='blocked_primary_btn' onClick={() => {
                            window.close();
                        }}>
                            Close
                        </button>
                        <button className='blocked_secondary_btn'>
                            Open BlockerX
                        </button>
                    </div>
                </div>
                <div className='blocked_footer'>
                    <div className='left_empty_area'></div>
                </div>
            </section>
        )
    }
}

export default BlockedPage