// import logo from './logo.svg';
import "./App.css";
import LandingPage from "./Components/LandingPage/landingPage";
import SaleHeader from "./Components/SaleHeader/saleHeader";
import mixpanel from "mixpanel-browser";

mixpanel.init("d051baf39ffda3bf75a7b21ddff1eef4", { debug: false });

mixpanel.track("LandingPage", {
    open: "LandingPageOpened",
});

function App() {
    return (
        <div className="App">
            <LandingPage/>
        </div>
    );
}

export default App;
