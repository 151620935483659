import './createTicket.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faCircleCheck, faTrashCan, faTriangleExclamation, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { TextField } from '@mui/material';
import { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import addFile from './addFile.png'
import disableFile from './disableFile.png'
import { useNavigate } from "react-router-dom";

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

function CreateTicketPage() {
    const navigate = useNavigate();
    const [tagPopupOpen, setTagPopupOpen] = useState(false)
    const [addedFiles, setAddedFiles] = useState([])
    const [ongoingSubmit, setOngoingSubmit] = useState(false)
    const [appName, setAppName] = useState('BlockerX')
    const [platform, setPlatform] = useState('android')
    const [userEmail, setUserEmail] = useState('')
    const [emailValidationState, setEmailValidationState] = useState('pending')
    const [anonymousUserCheck, setAnonymousUserCheck] = useState(false)
    const [selectedTag, setSelectedTag] = useState(null)
    const [ticketDescription, setTicketDescription] = useState('')
    const [fileProgress, setFileProgress] = useState("10%")
    const [afterValidationUid, setAfterValidationUid] = useState(null)
    const [currentAlert, setCurrentAlert] = useState(null)
    const [ticketFlowPage, setTicketFlowPage] = useState(1)

    const tagPopupToogle = () => {
        setTagPopupOpen(!tagPopupOpen)
    }

    const paramsAppName = new URLSearchParams(window.location.search).get("appName")
    const paramsPlatform = new URLSearchParams(window.location.search).get("platform")
    const paramsEmail = new URLSearchParams(window.location.search).get("email")
    const paramsUid = new URLSearchParams(window.location.search).get("uid")

    async function loadFileProgress() {
        await sleep(500)
        setFileProgress("30%")
        await sleep(500)
        setFileProgress("50%")
        await sleep(500)
        setFileProgress("70%")
        await sleep(500)
        setFileProgress("100%")
    }

    async function openAlert(alertText) {
        setCurrentAlert(alertText)
    }

    async function closeAlert() {
        setCurrentAlert(null)
    }

    const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
        if (acceptedFiles.length) {
            setFileProgress("10%")
            setAddedFiles(previousFiles => [
                ...previousFiles,
                ...acceptedFiles.map(file => Object.assign(file, { preview: URL.createObjectURL(file) }))
            ])
            loadFileProgress()
        }
        if (rejectedFiles.length) {
            if (rejectedFiles[0].errors[0].code === 'file-too-large') {
                openAlert('One or more files you are trying to upload are more than 10 MB. Please add files less than 10 MB')
            } else {
                openAlert('You can add a maximum of 3 files only')
            }
        }
        console.log('Accepted', acceptedFiles)
        console.log('Rejected', rejectedFiles)
    }, [])
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ maxSize: 30 * 1024 * 1000, maxFiles: (3 - addedFiles.length), onDrop })
    const removeAllAddedFiles = () => {
        setAddedFiles([])
    }

    const removeSingleAcceptFile = (this_file) => {
        setAddedFiles(files => files.filter((file) => file.name !== this_file))
    }

    const changePlatform = (newPlatform) => {
        setPlatform(newPlatform)
    }

    function setTicketInDatabase(formData) {
        return new Promise((resolve, reject) => {
            axios.post('https://accounts.blockerx.net/api/tickets', formData)
                .then((res1) => {
                    try {
                        const ticketId = res1.data.data
                        resolve(ticketId)
                    } catch (e) {
                        reject(e)
                    }
                })
                .catch((err1) => {
                    reject(err1)
                })
        })
    }

    const validateEmail = () => {
        if (userEmail && userEmail !== undefined && userEmail.length > 0 && userEmail.includes('@')) {
            axios.post('https://accounts.blockerx.net/api/tickets/dashboard/verifyEmail', {
                email: userEmail
            })
                .then((res1) => {
                    try {
                        if (res1.data.status === 200) {
                            setAfterValidationUid(res1.data.data.uid)
                            setEmailValidationState('done')
                        } else {
                            openAlert('This Email is not Registered with BlockerX')
                        }
                    } catch {
                        openAlert('This Email is not Registered with BlockerX')
                    }
                })
                .catch((err1) => {
                    openAlert('This Email is not Registered with BlockerX')
                })
        } else {
            openAlert('Enter a valid Email Id')
        }
    }

    const createNewTicket = async () => {
        setOngoingSubmit(true)
        if (userEmail && userEmail !== undefined && userEmail.length > 0 && userEmail.includes('@')) {
            if (emailValidationState === 'pending') {
                openAlert('Email address is required to be validated. If you do not have an account in BlockerX: Select the checkbox to continue')
            } else {
                const formData = new FormData()
                addedFiles.forEach(file => formData.append('file', file))

                let TicketObject = {
                    appName,
                    platform,
                    tag: selectedTag,
                    email: userEmail,
                    validated: true,
                    description: ticketDescription
                }
                if (emailValidationState === 'not_required') {
                    TicketObject.validated = false
                }
                if (afterValidationUid) {
                    TicketObject.uid = afterValidationUid
                }
                formData.append('appName', TicketObject.appName)
                formData.append('platform', TicketObject.platform)
                formData.append('tag', TicketObject.tag)
                formData.append('email', TicketObject.email)
                formData.append('validated', TicketObject.validated)
                formData.append('description', TicketObject.description)
                formData.append('uid', TicketObject.uid)
                if (paramsAppName && paramsPlatform && paramsEmail && paramsUid) {
                    formData.append('sourceApp', paramsAppName)
                    formData.append('sourcePlatform', paramsPlatform)
                    formData.append('sourceEmail', paramsEmail)
                    formData.append('sourceUid', paramsUid)
                }

                const RESULT = await setTicketInDatabase(formData)
                if (RESULT) {
                    if (paramsEmail && paramsUid) {
                        navigate("/ticket/view?email=" + paramsEmail + "&uid=" + paramsUid)
                    } else {
                        navigate("/ticket/view")
                    }
                } else {
                    setOngoingSubmit(false)
                }
            }
        } else {
            openAlert('Please add a Valid Email')
        }
        setOngoingSubmit(false)
    }

    const selectFromPage3 = () => {
        if (selectedTag) {
            if (ticketDescription && ticketDescription !== undefined && ticketDescription.trim().length > 19) {
                setTicketFlowPage(4)
            } else {
                openAlert('Give a proper Description of atleast 20 characters')
            }
        } else {
            openAlert('Please select a proper Category for your Issue')
        }
    }

    useEffect(() => {
        if (paramsAppName && paramsPlatform) {
            if (paramsAppName === 'blockerx') {
                setAppName('BlockerX')
            } else if (paramsAppName === 'healify') {
                setAppName('Healify')
            } else if (paramsAppName === 'zenze') {
                setAppName('Zenze')
            } else if (paramsAppName === 'dtox') {
                setAppName('DTox')
            } else if (paramsAppName === 'socialx') {
                setAppName('SocialX')
            }

            if (paramsPlatform) {
                setPlatform(paramsPlatform)
            }

            if (paramsEmail && paramsUid) {
                setUserEmail(paramsEmail)
                setAfterValidationUid(paramsUid)
                setEmailValidationState('done')
            }

            if (paramsAppName === 'zenze') {
                setTicketFlowPage(3)
            }
        }
    }, []);

    return (
        <section id='ticket_form_container'>
            <div className='ticket_form_header'>
                <div className='empty_liner'></div>
                <div className='ticket_form_heading'>
                    <h1>Register Issue</h1>
                </div>
            </div>
            {ticketFlowPage === 1 ? (
                <div className='ticket_form_body'>
                    <div className='form_content_area'>
                        <h2>Name of the App</h2>
                        <div className='form_content_options'>
                            <span onClick={() => { setAppName('BlockerX') }} className={appName === 'BlockerX' ? "selectedApp" : ""}>BlockerX</span>
                            <span onClick={() => { setAppName('Zenze') }} className={appName === 'Zenze' ? "selectedApp" : ""}>Zenze</span>
                            <span onClick={() => { setAppName('Healify') }} className={appName === 'Healify' ? "selectedApp" : ""}>Healify</span>
                            <span onClick={() => { setAppName('SocialX') }} className={appName === 'SocialX' ? "selectedApp" : ""}>SocialX</span>
                            <span onClick={() => { setAppName('DTox') }} className={appName === 'DTox' ? "selectedApp" : ""}>DTox</span>
                        </div>
                    </div>
                    <div className='form_submit_area'>
                        <button onClick={() => { setTicketFlowPage(2) }}>Continue</button>
                    </div>
                </div>
            ) : ticketFlowPage === 2 ? (
                <div className='ticket_form_body'>
                    <div className='form_content_area'>
                        <h2>Which platform are you using?</h2>
                        <div className='form_content_options'>
                            <span onClick={() => { changePlatform('android') }} className={platform === 'android' ? "correct" : ""}>Android</span>
                            <span onClick={() => { changePlatform('ios') }} className={platform === 'ios' ? "correct" : ""}>iOS</span>
                            <span onClick={() => { changePlatform('chrome') }} className={platform === 'chrome' ? "correct" : ""}>Chrome Extension</span>
                            <span onClick={() => { changePlatform('windows') }} className={platform === 'windows' ? "correct" : ""}>Windows</span>
                            <span onClick={() => { changePlatform('macos') }} className={platform === 'macos' ? "correct" : ""}>MacOs</span>
                        </div>
                    </div>
                    <div className='form_submit_area'>
                        <button onClick={() => { setTicketFlowPage(3) }}>Continue</button>
                    </div>
                </div>
            ) : ticketFlowPage === 3 ? (
                <div className='ticket_form_body'>
                    <div className='form_content_area'>
                        <h2>Choose a Category for your issue</h2>
                        <div className='platforms_form_tags_area'>
                            <div className='platform_form_tags_inner' onClick={tagPopupToogle}>
                                <p>{selectedTag === null ? "Select One" : selectedTag}</p>
                                <span>{tagPopupOpen ? (
                                    <FontAwesomeIcon color='#0D2952' icon={faAngleUp} />
                                ) : (
                                    <FontAwesomeIcon color='#0D2952' icon={faAngleDown} />
                                )}</span>
                            </div>
                        </div>
                        {tagPopupOpen ? (
                            <div className='platforms_form_tags_popup_area'>
                                <div className='platform_form_tag_popup'>
                                    <button onClick={() => {
                                        setSelectedTag('Premium')
                                        setTagPopupOpen(false)
                                    }}>Premium</button>
                                    <button onClick={() => {
                                        setSelectedTag('Streak')
                                        setTagPopupOpen(false)
                                    }}>Streak</button>
                                    <button onClick={() => {
                                        setSelectedTag('Blocking')
                                        setTagPopupOpen(false)
                                    }}>Blocking</button>
                                    <button onClick={() => {
                                        setSelectedTag('Partner')
                                        setTagPopupOpen(false)
                                    }}>Partner</button>
                                    <button onClick={() => {
                                        setSelectedTag('Bug')
                                        setTagPopupOpen(false)
                                    }}>Bug</button>
                                    <button onClick={() => {
                                        setSelectedTag('Loophole')
                                        setTagPopupOpen(false)
                                    }}>Loophole</button>
                                    <button onClick={() => {
                                        setSelectedTag('Switch')
                                        setTagPopupOpen(false)
                                    }}>Switch</button>
                                    <button onClick={() => {
                                        setSelectedTag('Others')
                                        setTagPopupOpen(false)
                                    }}>Others</button>
                                </div>
                            </div>
                        ) : (
                            <div></div>
                        )}
                        <h2 style={{ marginTop: '30px' }}>Description</h2>
                        <TextField style={{ marginTop: '10px' }} value={ticketDescription} onChange={(e) => { setTicketDescription(e.target.value) }} variant='outlined' label='Give a detailed info of the Problem' multiline rows={5} fullWidth={true} />
                    </div>
                    <div className='form_submit_area'>
                        <button onClick={selectFromPage3}>Continue</button>
                    </div>
                </div>
            ) : ticketFlowPage === 4 ? (
                <div className='ticket_form_body'>
                    <div className='form_content_area'>
                        <h2>Upload Supporting Documents</h2>
                        <p className='form_content_extra_info'> - You can add a maximum of 3 files</p>
                        <p className='form_content_extra_info'> - Each file should not exceed 20MB</p>
                        {addedFiles.length === 3 ? (
                            <div className='ticket_documents_area'>
                                <img src={disableFile} alt='drop' />
                                <p>You have added 3 Files. You cannot add more files</p>
                            </div>
                        ) : (
                            <div {...getRootProps()} className='ticket_documents_area'>
                                <input {...getInputProps()} />
                                <img src={addFile} alt='drop' />
                                {
                                    isDragActive ?
                                        <p>Drop the files here ...</p> :
                                        <p>Drag and drop some files here, or click to select files</p>
                                }
                            </div>
                        )}
                        {addedFiles.length ? (
                            <h2>Uploaded Files: </h2>
                        ) : (
                            <></>
                        )}
                        {addedFiles.length ? (
                            <div className='remove_all_files_area'>
                                <button onClick={removeAllAddedFiles}>
                                    <FontAwesomeIcon color='#ffffff' icon={faTrashCan} />
                                    <span>Remove all</span>
                                </button>
                            </div>
                        ) : (
                            <></>
                        )}
                        {addedFiles.length ? (
                            <div className='list_of_added_files'>
                                {addedFiles.map((single_file) => {
                                    return (
                                        <div className='single_added_file'>
                                            <div className='file_status_icon'>
                                                <FontAwesomeIcon color='#2B5162' icon={faCircleCheck} />
                                            </div>
                                            <div className='added_files_progress_area'>
                                                <span>{single_file.name}</span>
                                                <div className='added_files_progress'>
                                                    <div className='added_files_progress_inner' style={{ "width": fileProgress }}>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className='added_file_cta'>
                                                <button onClick={() => { removeSingleAcceptFile(single_file.name) }}><FontAwesomeIcon color='#d62623' icon={faTrashCan} /></button>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                    <div className='form_submit_area'>
                        <button onClick={() => { setTicketFlowPage(5) }}>Continue</button>
                    </div>
                </div>
            ) : (
                <div className='ticket_form_body'>
                    <div className='form_content_area'>
                        <h2>Email Verification</h2>
                        <div className='platforms_form_email_area'>
                            <input value={userEmail} onChange={(e) => {
                                setUserEmail(e.target.value)
                            }} type='text' placeholder='Enter your Email address' />
                            {emailValidationState === 'pending' ? (
                                <button onClick={validateEmail}>Validate</button>
                            ) : emailValidationState === 'not_required' ? (
                                <button disabled>No Validation Required</button>
                            ) : (
                                <button disabled>Validated</button>
                            )}

                        </div>
                        <div className='platforms_form_email_check_area'>
                            <input disabled={emailValidationState === 'done' ? true : false} id='ticket_form_checkbox' type='checkbox' checked={anonymousUserCheck} onChange={(e) => {
                                setAnonymousUserCheck(e.target.checked)
                                if (e.target.checked) {
                                    setEmailValidationState('not_required')
                                } else {
                                    setEmailValidationState('pending')
                                }
                            }} />
                            <label htmlFor='ticket_form_checkbox'>Don't have an account with {appName}</label>
                        </div>
                    </div>
                    <div className='form_submit_area'>
                        {ongoingSubmit ? (
                            <button disabled className='ticket_submit_btn'>Submitting.....</button>
                        ) : (
                            <button onClick={createNewTicket} className='ticket_submit_btn'>Submit</button>
                        )}
                    </div>
                </div>
            )}
            {currentAlert ? (
                <div className='alert_container'>
                    <div className='alert_card'>
                        <div className='close_area_alert'>
                            <FontAwesomeIcon onClick={closeAlert} color='#000000' icon={faCircleXmark} />
                        </div>
                        <div className='alert_content_area'>
                            <FontAwesomeIcon color='#ffbd4a' icon={faTriangleExclamation} />
                            <span>{currentAlert}</span>
                        </div>
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </section>
    )
}

export default CreateTicketPage;