import './supportChat.css'
import codi from './codi.png'
import load from './loading-paper.gif'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import waiting from './typing_wait.gif'
import axios from 'axios';
import mixpanel from 'mixpanel-browser';

mixpanel.init('d051baf39ffda3bf75a7b21ddff1eef4', { debug: false });

function ZenzeSupportPage() {

    const navigate = useNavigate()

    const paramsPlatform = new URLSearchParams(window.location.search).get("platform")
    const paramsEmail = new URLSearchParams(window.location.search).get("email")
    const paramsUid = new URLSearchParams(window.location.search).get("uid")

    return (
        <section id="chatbot_page">
            <div>
                <div className='chatbot_header_container'>
                    <div className='chatbot_icon_area'>
                        <img src={codi} alt='icon' />
                    </div>
                    <div className='chatbot_content_area'>
                        <h1>Zenze Support</h1>
                    </div>
                </div>
            </div>
            <div className='chatbot_body_navigation_area'>
                <button onClick={() => {
                    navigate("/ticket/create?appName=zenze&platform=" + paramsPlatform + "&email=" + paramsEmail + "&uid=" + paramsUid)
                }}>Register New Issue</button>
                <button onClick={() => {
                    navigate("/ticket/view?email=" + paramsEmail + "&uid=" + paramsUid)
                }}>View Ticket Status</button>
            </div>
        </section>
    )
}

export default ZenzeSupportPage;