import { useEffect, useState } from 'react';
import './dailyTask.css'
import { useParams } from 'react-router-dom';
import axios from 'axios';

function DailyTaskPage() {
    const { day } = useParams()
    const [isloading, setIsloading] = useState(true)
    const [pageContent, setPageContent] = useState([])

    function getTaskOfTheDay (day) {
        return new Promise((resolve, reject) => {
            axios.get('https://healify.blockerx.net/api/v1/getTodaysTask', {
                headers: {
                    day
                }
            })
                .then((result) => {
                    resolve(result.data)
                })
                .catch((error1) => {
                    resolve([])
                })
        })
    }

    async function setupTaskOfTheDay(day) {
        const DATA = await getTaskOfTheDay(day)
        try {
            if (DATA.status === 'success') {
                setPageContent(DATA.data)
                setIsloading(false)
            } else {
                alert('Something went wrong 1')
            }
        } catch (e) {
            alert('Something went wrong 2')
        }
    }

    useEffect(() => {
        setupTaskOfTheDay(day)
    }, []);
    if (isloading) {
        return (
            <section id="loading_screen">
                <img src="https://feed-posts.s3.amazonaws.com/blocker-chat/assets/loading.gif" alt="" />
            </section>
        )
    } else {
        return (
            <section className='task_container'>
                <h1>Day {day}</h1>
                <div className='task_content_area'>
                    {pageContent.map((singleContent) => {
                        console.log('Single Content', singleContent)
                        if (singleContent.type === 'heading') {
                            return (
                                <h2>{singleContent.content}</h2>
                            )
                        } else if (singleContent.type === 'paragraph') {
                            return (
                                <p>{singleContent.content}</p>
                            )
                        }
                    })}
                </div>
            </section>
        )
    }
}

export default DailyTaskPage;