import { useEffect } from 'react'
import './stripePaymentSuccess.css'
import redirectJson from './redirectLottie.json'
import { Player } from '@lottiefiles/react-lottie-player';

function StripeLimitSuccessPage() {
    useEffect(() => {
        const timer = setTimeout(() => {
            window.open(`chrome-extension://jkkfeklkdokfnlfodkdafododleokdoa/Dashboard/dashboard.html?listItemApproval=true`, '_self')
        }, 1500);
        return () => clearTimeout(timer);
    }, []);
    return (
        <section id='stripe_payment_success_container'>
            <h1>Redirecting you back to the app....</h1>
            {/* <img src={redirect} alt='redirect' /> */}
            <Player
                src={redirectJson}
                className="player"
                loop
                autoplay
            />
        </section>
    )
}

export default StripeLimitSuccessPage;