import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLock, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import '../DeleteUserAccount/deleteUserAccount.css'
import { useState } from 'react';
import axios from 'axios';
import quitPornLogo from './Quit Porn.png';
import { useNavigate } from "react-router-dom";
import quitPornAuth, { quitPornProvider } from '../../Connections/quitPornFirebase';
import { signInWithPopup } from 'firebase/auth';

function DeleteQuitPornAccount() {

    const navigate = useNavigate();

    const [userEmail, setUserEmail] = useState("")
    const [password, setPassword] = useState("")
    const [uid, setUid] = useState("")
    const [emailToDelete, setEmailToDelete] = useState("test")
    const [showPopup, setShowPopup] = useState(false)
    const [deleteComplete, setDeleteComplete] = useState(false)

    function deleteUserFromFirebase(uid) {
        return new Promise((resolve, reject) => {
            console.log({uid})
            axios.post('https://apps-server.blockerx.net/api/v1/quit-porn/firebase-user', {
                data: {
                    uid,
                    type: 'delete_account'
                }
            })
                .then((res2) => {
                    console.log({res2})
                    resolve(res2)
                })
                .catch((err2) => {
                    reject(err2)
                })
        })
    }

    function signInWithGoogle() {
        return new Promise((resolve, reject) => {
            signInWithPopup(quitPornAuth, quitPornProvider)
                .then((result) => {
                    resolve(result)
                })
                .catch((error) => {
                    resolve(error)
                })
        })
    }

    const deleteBlockerXAccount = async () => {
        if (userEmail !== null && userEmail !== undefined && userEmail.trim().length > 2) {
            if (password !== null && password !== undefined && password.trim().length > 2) {
                alert('The Credentials are invalid, Try Deleting using Google OAuth')
            } else {
                alert('Enter the Password correctly')
            }
        } else {
            alert('Enter a valid Email address')
        }
    }

    const closePopupAndReset = () => {
        setUid("")
        setUserEmail("")
        setPassword("")
        setEmailToDelete("")
        setShowPopup(false)
    }

    const deleteAccountFinal = async () => {
        if (uid && uid !== undefined) {
            const RESULT = await deleteUserFromFirebase(uid)
            if (RESULT) {
                try {
                    if (RESULT.data.status === 200) {
                        setUid("")
                        setUserEmail("")
                        setPassword("")
                        setEmailToDelete("")
                        setShowPopup(false)
                        setDeleteComplete(true)
                    } else {
                        alert('Something went wrong')
                        setUid("")
                        setUserEmail("")
                        setPassword("")
                        setEmailToDelete("")
                        setShowPopup(false)
                    }
                } catch (e) {
                    alert('Something went wrong')
                    setUid("")
                    setUserEmail("")
                    setPassword("")
                    setEmailToDelete("")
                    setShowPopup(false)
                }
            } else {
                alert('Something went wrong')
                setUid("")
                setUserEmail("")
                setPassword("")
                setEmailToDelete("")
                setShowPopup(false)
            }
        } else {
            alert('Something went wrong')
            setUid("")
            setUserEmail("")
            setPassword("")
            setEmailToDelete("")
            setShowPopup(false)
        }
    }

    const deleteBlockerXAccountwithGoogle = async () => {
        const RESULT = await signInWithGoogle()
        if (RESULT) {
            console.log({RESULT})
            try {
                if (RESULT.user.uid && RESULT.user.uid !== undefined && RESULT.user.uid.length > 2) {
                    setUid(RESULT.user.uid)
                    setEmailToDelete(RESULT.user.email)
                    setShowPopup(true)
                } else {
                    alert('Request failed')
                }
            } catch (e) {
                alert('Request failed')
            }
        } else {
            alert('Request failed')
        }
    }

    return (
        <section className="deleteUserContainer">
            <div className="delete_page_card">
                <div className="delete_page_head">
                    <span>VERIFY YOUR</span>
                    <div className='delete_page_brand_logo'>
                        <div className='brand_logo_box'>
                            <img src={quitPornLogo} alt='Quit Porn Now'/>
                        </div>
                        <h3 style={{color: '#E32E58'}}>Quit Porn Now</h3>
                    </div>
                    <span>ACCOUNT</span>
                </div>
                <div className="delete_page_form_area">
                    <div className="delete_page_email">
                        <FontAwesomeIcon icon={faUser} />
                        <input type='email' placeholder='Email' value={userEmail} onChange={(e) => { setUserEmail(e.target.value) }} />
                    </div>
                    <div className="delete_page_password">
                        <FontAwesomeIcon icon={faLock} />
                        <input type='password' placeholder='Password' value={password} onChange={(e) => { setPassword(e.target.value) }} />
                    </div>
                    <div className="delete_page_submit">
                        <button id='quit_porn_delete_btn' onClick={deleteBlockerXAccount}>Delete Account</button>
                    </div>
                </div>
                <div className='delete_page_partition'>
                    or
                </div>
                <div className='delete_page_google_area'>
                    <button onClick={deleteBlockerXAccountwithGoogle}><span>Delete account using</span> <img width="23px" src="https://feed-posts.s3.amazonaws.com/blockerx-landing-page/assets/googleIcon.png" alt="google_icon" /></button>
                </div>
            </div>
            {(() => {
                if (showPopup) {
                    return (
                        <div className='delete_confirmation_popup'>
                            <div className='delete_confirmation_card'>
                                <h1><FontAwesomeIcon icon={faTriangleExclamation} /> Are you sure ?</h1>
                                <span>Account Email: <u>{emailToDelete}</u></span>
                                <p>This will permanently delete your Quit Porn Now account and all you data linked with this account.</p>
                                <div className='delete_confirmation_btn_area'>
                                    <button onClick={closePopupAndReset}>Cancel</button>
                                    <button onClick={deleteAccountFinal} className='delete_confirmation_submit'>Delete</button>
                                </div>
                            </div>
                        </div>
                    )
                }
            })()}
            {(() => {
                if (deleteComplete) {
                    return (
                        <div className='delete_confirmation_popup'>
                            <div className='delete_confirmation_card'>
                                <p>Your Account has been successfully Deleted</p>
                                <div className='delete_confirmation_btn_area'>
                                    <button onClick={() => navigate("/")} className='delete_confirmation_submit'>Done</button>
                                </div>
                            </div>
                        </div>
                    )
                }
            })()}
        </section>
    )
}

export default DeleteQuitPornAccount