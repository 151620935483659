import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from 'firebase/auth'
const parentalControlFirebaseConfig = {
  apiKey: "AIzaSyB8AUdwl8qFfbzoa8qpNg8rSvScPZ5qwXU",
  authDomain: "parental-control-ac234.firebaseapp.com",
  projectId: "parental-control-ac234",
  storageBucket: "parental-control-ac234.firebasestorage.app",
  messagingSenderId: "577383483372",
  appId: "1:577383483372:web:3ccc682388b52d734762c9",
  measurementId: "G-XMFXV70MX6"
};

// Initialize Firebase
const parentalControlApp = initializeApp(parentalControlFirebaseConfig, "ninth");
const parentalControlAuth = getAuth(parentalControlApp);
const parentalControlProvider = new GoogleAuthProvider();

export default parentalControlAuth;

export { parentalControlProvider };