import React, { useEffect, useRef, useState } from 'react';
import './dailyTasks.css';
import load from '../SupportChat/loading-paper.gif'
import axios from 'axios';
import marker_icon from './top_icon.png'
import { useNavigate } from 'react-router-dom';
import icon1 from './module_icons/Article.png';
import icon2 from './module_icons/Exercise.png';
import icon3 from './module_icons/Indepth Reading.png';
import icon4 from './module_icons/JournalType.png';
import icon5 from './module_icons/Questionanaire.png';
import icon6 from './module_icons/VideoType.png';

const DailyTasks = () => {

    const paramsToken = new URLSearchParams(window.location.search).get("userToken")

    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true)
    const [userInvalid, setUserInvalid] = useState(true)
    const [uid, setUid] = useState(null)
    const [courseStructure, setCourseStructure] = useState([])
    const [completedElements, setCompletedElements] = useState(0)
    const [contentType, setContentType] = useState(null)
    const [videoPlaying, setVideoPlaying] = useState(false)
    const [onGoingModule, setOngoingModule] = useState(0)
    const [videoData, setVideoData] = useState(null)
    const [exerciseData, setExerciseData] = useState(null)
    const [readingData, setReadingData] = useState(null)
    const [questionListData, setQuestionList] = useState(null)
    const [activeQuestion, setActiveQuestion] = useState(0)
    const [activeOption, setActiveOption] = useState(null)
    const [questionAllowAttempts, setQuestionAllowAttempts] = useState(true)
    const [showQuestionResult, setShowQuestionResult] = useState('none')
    const [booksData, setBooksData] = useState(null)

    // const divref = useRef()

    function isValidToken(paramToken) {
        return new Promise((resolve, reject) => {
            axios.post('https://accounts.blockerx.net/api/verify/userToken', {
                token: paramToken
            })
                .then((response) => {
                    console.log(' 2: ', response.data)
                    if (response.data.status === 'success' && response.data.data.data) {
                        setUid(response.data.data.message)
                        resolve({
                            status: true,
                            userId: response.data.data.message
                        })
                    } else {
                        resolve({
                            status: false
                        })
                    }
                })
                .catch((error2) => {
                    console.log(' Error 2: ', error2)
                    resolve({
                        status: false
                    })
                })
        })
    }

    function getCourseProgress(thisUser) {
        return new Promise((resolve, reject) => {
            axios.get('https://dev.blockerx.net/api/v1/getDeaddictionData')
                .then((response2) => {
                    console.log(' 2: ', response2.data)
                    if (response2.data.status === 200 && 'data' in response2.data && response2.data.data && 'formattedData' in response2.data.data && response2.data.data.formattedData && 'days' in response2.data.data.formattedData && response2.data.data.formattedData.days) {
                        setCourseStructure(response2.data.data.formattedData.days)
                        axios.get(`https://dev.blockerx.net/api/v1/course-ops/user-progress?uid=${thisUser}&course=deaddiction_program`)
                            .then((response3) => {
                                console.log(' 3: ', response3.data)
                                if (response3.data.status === 200 && 'data' in response3.data && response3.data.data && 'progress' in response3.data.data && response3.data.data.progress && 'completedCount' in response3.data.data.progress && response3.data.data.progress.completedCount) {
                                    setCompletedElements(response3.data.data.progress.completedCount)
                                    resolve({
                                        status: true
                                    })
                                } else {
                                    setCompletedElements(0)
                                    resolve({
                                        status: true
                                    })
                                }
                            })
                            .catch((error3) => {
                                console.log(' Error 3: ', error3)
                                resolve({
                                    status: false
                                })
                            })
                    } else {
                        resolve({
                            status: false
                        })
                    }
                })
                .catch((error2) => {
                    console.log(' Error 2: ', error2)
                    resolve({
                        status: false
                    })
                })
        })
    }

    function updateCourseProgress(sequence) {
        return new Promise((resolve, reject) => {
            axios.post('https://dev.blockerx.net/api/v1/course-ops/user-progress', {
                uid,
                course: "3_week_bootcamp",
                completedCount: sequence
            })
                .then((response) => {
                    console.log(' Updated: ', response.data)
                    if (response.data.status === 200) {
                        resolve({
                            status: true
                        })
                    } else {
                        resolve({
                            status: false
                        })
                    }
                })
                .catch((error) => {
                    console.log(' Error 2: ', error)
                    resolve({
                        status: false
                    })
                })
        })
    }

    async function verifyUserToken() {
        if (paramsToken && paramsToken !== undefined && paramsToken.length) {
            if (paramsToken === 'testBootCampCode29') {
                setUid('htT9yDhI2iNXF1gq7YMSttBkYdJ2')
                await getCourseProgress('htT9yDhI2iNXF1gq7YMSttBkYdJ2')
                setUserInvalid(false)
                setIsLoading(false)
            } else {
                const RESULT = await isValidToken(paramsToken)
                if (RESULT && RESULT.status && RESULT.userId) {
                    await getCourseProgress(RESULT.userId)
                    setUserInvalid(false)
                    setIsLoading(false)
                } else {
                    setUserInvalid(true)
                    setIsLoading(false)
                }
            }
        } else {
            setUserInvalid(true)
            setIsLoading(false)
        }
    }

    // async function verifyUserToken() {
    //     if (paramsToken && paramsToken !== undefined && paramsToken.length) {
    //         if (paramsToken === 'testBootCampCode29') {
    //             setUid('htT9yDhI2iNXF1gq7YMSttBkYdJ2')
    //             await getCourseProgress('htT9yDhI2iNXF1gq7YMSttBkYdJ2')
    //             setUserInvalid(false)
    //             setIsLoading(false)
    //             setTimeout(() => {
    //                 const { current } = divref
    //                 if (current !== null) {
    //                     current.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" })
    //                 }
    //             }, 1000)
    //         } else {
    //             const RESULT = await isValidToken(paramsToken)
    //             if (RESULT && RESULT.status && RESULT.userId) {
    //                 await getCourseProgress(RESULT.userId)
    //                 setUserInvalid(false)
    //                 setIsLoading(false)
    //                 setTimeout(() => {
    //                     const { current } = divref
    //                     if (current !== null) {
    //                         current.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" })
    //                     }
    //                 }, 1000)
    //             } else {
    //                 setUserInvalid(true)
    //                 setIsLoading(false)
    //             }
    //         }
    //     } else {
    //         setUserInvalid(true)
    //         setIsLoading(false)
    //     }
    // }

    // const scrollToCurrentModule = () => {
    //     const { current } = divref
    //     if (current !== null) {
    //         current.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" })
    //     }
    // }

    const markAsDone = async (sequence) => {
        setIsLoading(true)
        if (sequence > completedElements) {
            const RESULT = await updateCourseProgress(sequence)
            console.log({ RESULT })
            if (RESULT && RESULT.status) {
                setCompletedElements(sequence)
                setOngoingModule(0)
                setVideoPlaying(false)
                setContentType(null)
                setIsLoading(false)
            } else {
                setOngoingModule(0)
                setVideoPlaying(false)
                setContentType(null)
                setIsLoading(false)
            }
        } else {
            setOngoingModule(0)
            setVideoPlaying(false)
            setContentType(null)
            setIsLoading(false)
        }
    }

    // async function openVideoModule(sequence, resource, title, description, duration, thumbnail) {
    //     setOngoingModule(sequence)
    //     const newVideoData = {
    //         title,
    //         description,
    //         duration,
    //         thumbnail,
    //         resource
    //     }
    //     setVideoData(newVideoData)
    //     setContentType('Video')
    // }

    // async function openReadingModule(sequence, resource) {
    //     setOngoingModule(sequence)
    //     setReadingData(resource)
    //     setContentType('Reading')
    // }

    // async function openExerciseModule(sequence, resource, title, description, thumbnail) {
    //     setOngoingModule(sequence)
    //     const newExerciseData = {
    //         title,
    //         description,
    //         thumbnail,
    //         resource
    //     }
    //     setExerciseData(newExerciseData)
    //     setContentType('Exercise')
    // }

    // async function openQuestionModule(sequence, questionList) {
    //     setOngoingModule(sequence)
    //     setQuestionList(questionList)
    //     setActiveQuestion(0)
    //     setActiveOption(null)
    //     setQuestionAllowAttempts(true)
    //     setContentType('Questionnaire')
    // }

    // async function openBooksModule(sequence, bookContent, thumbnail, title) {
    //     setOngoingModule(sequence)
    //     const newBooksData = {
    //         title,
    //         thumbnail,
    //         content: bookContent
    //     }
    //     setBooksData(newBooksData)
    //     console.log('Checking Book', { newBooksData })
    //     setContentType('Books')
    // }

    useEffect(() => {
        verifyUserToken()
    }, [])

    if (isLoading) {
        return (
            <section className='course_window'>
                <div className='loading_container'>
                    <img src={load} alt='loading' />
                </div>
            </section>
        )
    } else if (userInvalid) {
        return (
            <section className='course_window'>
                <div className='lost_screen'>
                    <h1>You seem to be lost</h1>
                    <img src="https://feed-posts.s3.amazonaws.com/blocker-chat/assets/lostPageImg.gif" alt="" />
                </div>
            </section>
        )
    } else {
        return (
            <section className='daily_tasks_container'>
                <div className='daily_heading'>
                    <img src={marker_icon} alt='marker' />
                    <h1>PORN DE-ADDICTION JOURNEY</h1>
                </div>
                <div className='daily_header_area'>
                    <div className='daily_header_inner'>
                        <h2>DAY 1</h2>
                        <h3>Self Help Guide to Porn Addiction</h3>
                    </div>
                </div>
                <div className='daily_tasks_area'>
                    <div className='daily_tasks_list'>
                        <div className='daily_task_element' style={{ justifyContent: 'flex-end' }}>
                            <div className='session_button_area'>
                                <button>
                                    <div className="task_info">WATCH VIDEO</div>
                                    <img src={icon1} alt='Video' />
                                </button>
                            </div>
                        </div>
                        <div className='daily_task_element' style={{ justifyContent: 'flex-start' }}>
                            <div className='session_button_area'>
                                <button>
                                    <div className="task_info">WATCH VIDEO</div>
                                    <img src={icon2} alt='Video' />
                                </button>
                            </div>
                        </div>
                        <div className='daily_task_element' style={{ justifyContent: 'flex-end' }}>
                            <div className='session_button_area'>
                                <button>
                                    <div className="task_info">WATCH VIDEO</div>
                                    <img src={icon3} alt='Video' />
                                </button>
                            </div>
                        </div>
                        <div className='daily_task_element' style={{ justifyContent: 'flex-start' }}>
                            <div className='session_button_area'>
                                <button>
                                    <div className="task_info">WATCH VIDEO</div>
                                    <img src={icon4} alt='Video' />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
};

export default DailyTasks;