import React, { useState } from "react";
import './chromeUninstall.css'
import heroImg from './uninstallImg.png'
import { useEffect } from "react";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import mixpanel from 'mixpanel-browser';

mixpanel.init('d051baf39ffda3bf75a7b21ddff1eef4', { debug: false });

function ChromeUninstall() {

    const navigate = useNavigate();

    const uid = new URLSearchParams(window.location.search).get('uid')

    const [suggestion, setSuggestion] = useState('')

    const openChromeStore = () => {
        window.open('https://chrome.google.com/webstore/detail/blockerx-block-distractin/jkkfeklkdokfnlfodkdafododleokdoa', '_blank')
    }

    function sendUserSuggestion (uid, suggestion) {
        return new Promise((resolve, reject) => {
            axios.post('https://accounts.blockerx.net/api/setFeedback', {
                uid,
                platform: 'chrome',
                objective: 'after_uninstall',
                suggestion
            })
                .then((response1) => {
                    resolve({
                        data: true
                    })
                })
                .catch((error1) => {
                    resolve({
                        data: true
                    })
                })
        })
    }

    const sendSuggestion = async () => {
        if (suggestion && suggestion !== undefined && suggestion.trim().length > 0) {
            setSuggestion(suggestion.trim())
            const RESULT = await sendUserSuggestion(uid, suggestion)
            if (RESULT && RESULT.data) {
                navigate("/chrome-uninstall/thank-you")
            } else {
                navigate("/chrome-uninstall/thank-you")
            }
        } else {
            alert('Please add a valid suggestion')
        }
    }

    const sendUninstallEvent = (uid) => {
        if (uid && uid !== undefined) {
            mixpanel.track('ChromeUninstall', {
                'uid': uid
            })
            axios.post('https://accounts.blockerx.net/api/extensionUninstall', {
                uid
            })
                .then((response) => {
                })
                .catch((error2) => {
                })
        } else {
            mixpanel.track('ChromeUninstall', {
                'uid': 'anonymous'
            })
        }
    }

    useEffect(() => {
        sendUninstallEvent(uid)
    }, []);

    return (
        <div className="uninstall_main_container">
            <div className="uninstall_main_inner">
                <div className="uninstall_head">
                    <img src="https://feed-posts.s3.amazonaws.com/blockerx-landing-page/assets/icon.png" alt="brand-logo" />
                </div>
                <div className="unintsall_body">
                    <div className="uninstall_body_install_again">
                        <div className="install_again_img_area">
                            <img src={heroImg} alt="" />
                        </div>
                    </div>
                    <div className="uninstall_body_reason_form">
                        <div className="install_again_sentence_area">
                            <h2>We are always there if you need us</h2>
                        </div>
                        <div className="install_again_button_area">
                            <button onClick={openChromeStore}>Install again</button>
                        </div>
                        <div className="reason_text">
                            <textarea value={suggestion} onChange={(e) => { setSuggestion(e.target.value) }} placeholder="Let us know how we can improve" rows='5'></textarea>
                        </div>
                        <div className="install_again_button_area">
                            <button onClick={sendSuggestion}>Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChromeUninstall;