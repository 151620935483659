import * as React from 'react';
import './support.css';

function SupportPage() {
    return (
        <>
            <div>
                <main>
                    <section>
                        <div id='main-area' className="overTopAll">
                            <div className="homeOuter23">
                                <div className="contentHome1">
                                    <div className="homeText2">
                                        <h2 className="centText">
                                            Welcome to BlockerX support.<br />
                                        </h2>
                                        <h2 className="centText">
                                            To raise a support ticket, please email us at <a href='mailto:support@blocker.org'>support@blockerx.org</a>.<br />
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </div>
        </>
    );
}

export default SupportPage;