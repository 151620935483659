import { useEffect, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import './articles.css'
import load from '../SupportChat/loading-paper.gif'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';

function ArticlesPage() {
    const { criteria } = useParams()

    const ALL_ARTICLES = [
        {
            title: "HOW TO REMOVE BLOCKERX FROM A CHILD'S DEVICE?",
            url: "https://blockerx.freshdesk.com/support/solutions/articles/81000398798"
        }, {
            title: "HOW DO I CONTINUE MY EXISTING BLOCKERX PREMIUM SUBSCRIPTION ON A NEW DEVICE?",
            url: "https://blockerx.freshdesk.com/support/solutions/articles/81000398894"
        }, {
            title: "HOW TO REMOVE A LONG SENTENCES/TIME DELAY/CHANGE ACCOUNTABILITY PARTNER'S EMAIL ID?",
            url: "https://blockerx.freshdesk.com/support/solutions/articles/81000404881"
        }, {
            title: "HOW TO CHANGE ACCOUNTABILITY PARTNER ON iOS APP?",
            url: "https://blockerx.freshdesk.com/a/solutions/articles/81000404638"
        }, {
            title: "HOW TO TAKE PORN ADDICTION TEST (PAT)?",
            url: "https://blockerx.freshdesk.com/a/solutions/articles/81000404636"
        }, {
            title: "HOW TO UPDATE THE BILLING INFORMATION FOR STRIPE PAYMENT?",
            url: "https://blockerx.freshdesk.com/a/solutions/articles/81000404639"
        }, {
            title: "HOW TO ADD KEYWORD/WEBSITE/APP TO THE BLOCKLIST ON ANDROID APP?",
            url: "https://blockerx.freshdesk.com/a/solutions/articles/81000404641"
        }, {
            title: "HOW TO SET UP A CHROME EXTENSION?",
            url: "https://blockerx.freshdesk.com/a/solutions/articles/81000407853"
        }, {
            title: "HOW TO SET UP VPN ON iOS APP?",
            url: "https://blockerx.freshdesk.com/a/solutions/articles/81000407854"
        }, {
            title: "HOW TO ADD AN ACCOUNTABILITY PARTNER IN BLOCKERX FOR ANDROID?",
            url: "https://blockerx.freshdesk.com/a/solutions/articles/81000408811"
        }, {
            title: "HOW TO CHANGE BLOCKERX PASSWORD?",
            url: "https://blockerx.freshdesk.com/a/solutions/articles/81000409776"
        }, {
            title: "HOW TO INSTALL BLOCKERX CHROME EXTENSION?",
            url: "https://blockerx.freshdesk.com/a/solutions/articles/81000411635"
        }, {
            title: "HOW TO UNINSTALL BLOCKERX?",
            url: "https://blockerx.freshdesk.com/a/solutions/articles/81000411868"
        }, {
            title: "Adding and Removing an Accountability Partner",
            url: "https://blockerx.freshdesk.com/a/solutions/articles/81000411869"
        }, {
            title: "Cancelling and Refunding Your Subscription",
            url: "https://blockerx.freshdesk.com/a/solutions/articles/81000411871"
        }, {
            title: "Restoring Your Previous Streak",
            url: "https://blockerx.freshdesk.com/a/solutions/articles/81000411872"
        }, {
            title: "Troubleshooting Accessibility Issues",
            url: "https://blockerx.freshdesk.com/a/solutions/articles/81000411878"
        }, {
            title: "Enabling 'Prevent Uninstall' in BlockerX",
            url: "https://blockerx.freshdesk.com/a/solutions/articles/81000411880"
        }, {
            title: "Deleting Your BlockerX Account",
            url: "https://blockerx.freshdesk.com/a/solutions/articles/81000411892"
        }, {
            title: "Changing the Language in BlockerX App",
            url: "https://blockerx.freshdesk.com/a/solutions/articles/81000411979"
        }
    ]

    const navigate = useNavigate();

    const paramsUid = new URLSearchParams(window.location.search).get("uid")
    const paramsEmail = new URLSearchParams(window.location.search).get("email")
    const paramsAppName = new URLSearchParams(window.location.search).get("appName")
    const paramsPlatform = new URLSearchParams(window.location.search).get("platform")

    const [matchingArticleList, setMatchingArticleList] = useState(ALL_ARTICLES)
    const [searchQuery, setSearchQuery] = useState('')
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const timer = setTimeout(() => {
            if (criteria && criteria !== undefined) {
                if (criteria === 'add_partner' || criteria === 'remove_partner') {
                    const temp_articles = [
                        {
                            title: "HOW TO REMOVE A LONG SENTENCES/TIME DELAY/CHANGE ACCOUNTABILITY PARTNER'S EMAIL ID?",
                            url: "https://blockerx.freshdesk.com/support/solutions/articles/81000404881"
                        }, {
                            title: "HOW TO CHANGE ACCOUNTABILITY PARTNER ON iOS APP?",
                            url: "https://blockerx.freshdesk.com/a/solutions/articles/81000404638"
                        }, {
                            title: "Adding and Removing an Accountability Partner",
                            url: "https://blockerx.freshdesk.com/a/solutions/articles/81000411869"
                        }, {
                            title: "HOW TO ADD AN ACCOUNTABILITY PARTNER IN BLOCKERX FOR ANDROID?",
                            url: "https://blockerx.freshdesk.com/a/solutions/articles/81000408811"
                        }
                    ]
                    setMatchingArticleList(temp_articles)
                }
                setLoading(false)
            } else {
                setLoading(false)
            }
        }, 500);
        return () => clearTimeout(timer);
    }, [])

    if (loading) {
        return (
            <section id='chatbot_loading'>
                <img src={load} alt='loading' />
            </section>
        )
    } else if (criteria) {
        if (criteria === 'check_spam') {
            return (
                <section id='articles_container'>
                    <div className='articles_inner_container'>
                        <h1>Please check the 'Spam' or 'Junk' folder in your email, in case our message has been inadvertently filtered there.</h1>
                    </div>
                    <div className='articles_footer'>
                        <span onClick={() => {
                            if (paramsAppName && paramsPlatform && paramsEmail && paramsUid) {
                                navigate("/ticket/create?appName=" + paramsAppName + "&platform=" + paramsPlatform + "&email=" + paramsEmail + "&uid=" + paramsUid)
                            } else {
                                navigate("/ticket/create")
                            }
                        }}> Issue still not Resolved ? Create Ticket</span>
                </div>
                </section >
            )
        } else if (criteria === 'add_partner' || criteria === 'remove_partner') {
            return (
                <section id='articles_container'>
                    <div className='articles_inner_container'>
                        <h1> Check out these articles for queries related to adding or removing Partner </h1>
                        {matchingArticleList.length ? (
                            <div className='articles_list_area'>
                                {matchingArticleList.map((singleArticle) => {
                                    return (
                                        <div className='article_single_card' onClick={() => {
                                            window.open(singleArticle.url, '_blank')
                                        }}>
                                            <span>{singleArticle.title}</span>
                                            <FontAwesomeIcon color='#b7b7b7' icon={faArrowUpRightFromSquare} />
                                        </div>
                                    )
                                })}
                            </div>
                        ) : (
                            <div className='articles_list_area'>
                                <h3>Sorry! Could not find a suitable solution for your query</h3>
                            </div>
                        )}
                    </div>
                    <div className='articles_footer'>
                        <span onClick={() => {
                            if (paramsAppName && paramsPlatform && paramsEmail && paramsUid) {
                                navigate("/ticket/create?appName=" + paramsAppName + "&platform=" + paramsPlatform + "&email=" + paramsEmail + "&uid=" + paramsUid)
                            } else {
                                navigate("/ticket/create")
                            }
                        }}> Issue still not Resolved ? Create Ticket</span>
                </div>
                </section >
            )
        }
    } else {
        return (
            <section id='articles_container'>
                <div className='articles_inner_container'>
                    <h1> FAQ </h1>
                    <input type='text' placeholder='Search Here' value={searchQuery} onChange={(e) => {
                        setSearchQuery(e.target.value)
                        const tempList = ALL_ARTICLES.filter(article => article.title.toLowerCase().includes(e.target.value.toLowerCase()))
                        setMatchingArticleList(tempList)
                    }} />
                    {matchingArticleList.length ? (
                        <div className='articles_list_area'>
                            {matchingArticleList.map((singleArticle) => {
                                return (
                                    <div className='article_single_card' onClick={() => {
                                        window.open(singleArticle.url, '_blank')
                                    }}>
                                        <span>{singleArticle.title}</span>
                                        <FontAwesomeIcon color='#b7b7b7' icon={faArrowUpRightFromSquare} />
                                    </div>
                                )
                            })}
                        </div>
                    ) : (
                        <div className='articles_list_area'>
                            <h3>Sorry! Could not find a suitable solution for your query</h3>
                        </div>
                    )}
                </div>
                <div className='articles_footer'>
                    <span onClick={() => {
                        if (paramsAppName && paramsPlatform && paramsEmail && paramsUid) {
                            navigate("/ticket/create?appName=" + paramsAppName + "&platform=" + paramsPlatform + "&email=" + paramsEmail + "&uid=" + paramsUid)
                        } else {
                            navigate("/ticket/create")
                        }
                    }}> Issue still not Resolved ? Create Ticket</span>
            </div>
            </section >
        )
    }
}

export default ArticlesPage