import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from 'firebase/auth'
const quitPornFirebaseConfig = {
    apiKey: "AIzaSyAcS3pFTy4WqaSda7jU6NgFQWfcttbCkhs",
    authDomain: "quit-porn-now.firebaseapp.com",
    projectId: "quit-porn-now",
    storageBucket: "quit-porn-now.firebasestorage.app",
    messagingSenderId: "47714226013",
    appId: "1:47714226013:web:6e51222db73f4c390c62b4",
    measurementId: "G-DENH13SFNJ"
};

// Initialize Firebase
const quitPornApp = initializeApp(quitPornFirebaseConfig, "sixth");
const quitPornAuth = getAuth(quitPornApp);
const quitPornProvider = new GoogleAuthProvider();

export default quitPornAuth;

export { quitPornProvider };