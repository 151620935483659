import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from 'firebase/auth'
const appBlockerFirebaseConfig = {
  apiKey: "AIzaSyCf-ZMdzXZbew64NbBmFaodSwQMjchLmmo",
  authDomain: "app-blocker-b3d0b.firebaseapp.com",
  projectId: "app-blocker-b3d0b",
  storageBucket: "app-blocker-b3d0b.firebasestorage.app",
  messagingSenderId: "742022538956",
  appId: "1:742022538956:web:60e6b5c887d513033c4469",
  measurementId: "G-Z3BWSGJX7W"
};

// Initialize Firebase
const appBlockerApp = initializeApp(appBlockerFirebaseConfig, "eight");
const appBlockerAuth = getAuth(appBlockerApp);
const appBlockerProvider = new GoogleAuthProvider();

export default appBlockerAuth;

export { appBlockerProvider };