import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from 'firebase/auth'
const socialxFirebaseConfig = {
    apiKey: "AIzaSyABgYiU3JvIMaiBjuyglOqSdT2-drQmd9U",
    authDomain: "socialx-b59cc.firebaseapp.com",
    projectId: "socialx-b59cc",
    storageBucket: "socialx-b59cc.appspot.com",
    messagingSenderId: "858872334717",
    appId: "1:858872334717:web:2b77cc2a55a7d519922414",
    measurementId: "G-M29SL1QDRM"
  };

// Initialize Firebase
const socialxApp = initializeApp(socialxFirebaseConfig, "fifth");
const socialxAuth = getAuth(socialxApp);
const socialxProvider = new GoogleAuthProvider();

export default socialxAuth;

export { socialxProvider };