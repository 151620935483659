import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from 'firebase/auth'
const websiteBlockerFirebaseConfig = {
    apiKey: "AIzaSyCXwU_tTsoBgKyrxIyuKiaa004ceLRQGj8",
    authDomain: "website-blocker-c223d.firebaseapp.com",
    projectId: "website-blocker-c223d",
    storageBucket: "website-blocker-c223d.firebasestorage.app",
    messagingSenderId: "647197164961",
    appId: "1:647197164961:web:04dca0e605cf3d7382f7e0",
    measurementId: "G-WKGCYE7YEJ"
  };

// Initialize Firebase
const websiteBlockerApp = initializeApp(websiteBlockerFirebaseConfig, "seventh");
const websiteBlockerAuth = getAuth(websiteBlockerApp);
const websiteBlockerProvider = new GoogleAuthProvider();

export default websiteBlockerAuth;

export { websiteBlockerProvider };