import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from 'firebase/auth'
const healifyFirebaseConfig = {
    apiKey: "AIzaSyDQU_aVYm0mZAmSIT859GKDoxHrVuUvQlY",
    authDomain: "healify-faa5f.firebaseapp.com",
    projectId: "healify-faa5f",
    storageBucket: "healify-faa5f.appspot.com",
    messagingSenderId: "758269393485",
    appId: "1:758269393485:web:87b1c67a5e9326ed0b3a04",
    measurementId: "G-SMVKWNV4TH"
};

// Initialize Firebase
const healifyApp = initializeApp(healifyFirebaseConfig, "fourth");
const healifyAuth = getAuth(healifyApp);
const healifyProvider = new GoogleAuthProvider();

export default healifyAuth;

export { healifyProvider };