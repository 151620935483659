import './downloadAPK.css';

function DownloadAPK () {
    return (
        <section className="download_page">
            <button onClick={() => {
                window.open('https://blockerx-android-apk.s3.amazonaws.com/blockerx-android-apk-file.apk', '_self')
            }}>Download Android APK</button>
        </section>
    )
}

export default DownloadAPK;