import { useParams } from "react-router-dom"
import './communityGuide.css';

const CommunityGuide = () => {
    const {page} = useParams();

    if (page === '1') {
        return (
            <section className="community_content">
                <h1>Guide to BlockerX Community</h1>
                <h3>What is BlockerX?</h3>
                <p>BlockerX is an adult content blocker app mainly designed to help individuals who are trying to control their urges toward relapses. It not only blocks access to adult content but can also block gambling apps, gaming dating and limit social media apps. By removing these digital distractions BlockerX helps you in improving your productivity, focus, and relationships.</p>
                <p>BlockerX provides a safe search feature to ensure that you can browse the internet without the risk of interacting with any adult content. It filters out all such content in search engines like Google, Bing, etc helping you in maintaining a healthier digital lifestyle.</p>
                <h3>The BlockerX Community</h3>
                <p>One of the most important and vibrant aspects of BlockerX is its supportive community. With over 100k+ members, the BlockerX Community is a safe space for people, who are on a similar path of avoiding relapses and helping each other by sharing their experiences and in over addiction</p>
                <h3>What we do as a community.</h3>
                <p>In the BlockerX community, the user can post their experiences about the journey of being addiction-free, they can share the challenges they might have faced, and also enjoy the milestones together. The community brings out a feeling of togetherness which creates a supportive environment to feel encouraged and understood.</p>
                <p>In the community, the users can share three types of posts:</p>
                <ul>
                    <li>Image post</li>
                    <li>Text post</li>
                    <li>Poll post</li>
                </ul>
                <h3>How the community helps.</h3>
                <p>The journey to overcome addiction and bad habits is challenging and personal yet requires support. You don’t have to face everything alone, the community is here to help you throughout the journey. The BlockerX Community provides:</p>
                <ul>
                    <li>Motivation: Success stories of people and their achievements motivate the other community members</li>
                    <li>Peer Support: Connection with others who understand your struggle and their support towards you to stay strong.</li>
                    <li>Resources: The community posts bring out a large number of resources beneficial in overcoming digital addictions and improving productivity</li>
                </ul>
                <h3>Future Benefits of the Community.</h3>
                <p>As the BlockerX Community continues to grow, its potential to help the users only increases. Future benefits include:</p>
                <ul>
                    <li>Expanded Resources: More members mean more shared experiences, tips, and success stories.</li>
                    <li>Stronger Support Network: A larger community means more diverse perspectives and support, making it easier for every member to find the help they need.</li>
                </ul>
                <h3>Join the community today!</h3>
                <p>If you're ready to take control of your digital habits and join a supportive network of like-minded individuals, the BlockerX Community is here for you. Together, we can overcome challenges, build better habits, and achieve our goals.</p>
            </section>
        )
    } else if (page === '2') {
        return (
            <section className="community_content">
                <h1>Steps to publish your first Community post</h1>
                <p>Are you ready to engage with the BlockerX community and share your thoughts, questions, or even just a quick poll? Here’s a simple step-by-step guide on how to make your first Community post in BlockerX.</p>
                <p>With options to share text, images, or even create polls, you can connect with like-minded individuals and add your voice to the community. Let's dive in!</p>
                <h3>Step-by-Step Guide to Posting on the BlockerX Community</h3>
                <ol>
                    <li><strong>Open the BlockerX App: </strong>To get started, open the BlockerX app on your phone. Make sure you’re logged in to access the Community feature.</li>
                    <li><strong>Locate the "Forum" Option: </strong>At the bottom of the screen, you’ll see a navigation bar with multiple options. Look for Forum—that’s where the BlockerX community lives. Tap on it, and you’ll be taken to the Community section, where all the posts, discussions, and interactions happen.</li>
                    <li><strong>Click the Addition (“+”) Icon: </strong>Once you’re in the Forum section, you’ll notice a “+” icon at the bottom right corner of the screen. This button is your gateway to creating a new post. Tap it to get started!</li>
                    <li><strong>Choose Your Post Type: Text, Image, or Poll: </strong>
                        <p>Now comes the exciting part! After clicking the “+” icon, you’ll see three options for creating your post:</p>
                        <ul>
                            <li>Text Post: Perfect for sharing thoughts, advice, or starting a discussion. Type in whatever’s on your mind, whether it’s a motivational message, a question for the community, or a reflection on your journey.</li>
                            <li>Image Post: Sometimes, a picture says it all. Use this option if you want to share an inspiring image, a snapshot from your day, or anything else visually impactful. Just select a photo from your gallery and add any caption if needed.</li>
                            <li>Poll Post: Have a question with multiple-choice answers? The Poll option is great for quick feedback! Simply type your question, add a few choices, and let the community vote on it. It’s a fun way to interact and see what others think about various topics.</li>
                        </ul>
                    </li>
                    <li><strong>Craft Your Post:</strong> Now that you’ve chosen a type, you can create your post. Write out your thoughts, upload your image, or set up your poll options. Be authentic and engaging to encourage responses from the community.</li>
                    <li><strong>Submit Your Post</strong>Once you’re happy with how everything looks, hit Post! Your post will now appear in the Community section, where other users can view it, comment, and react.</li>
                </ol>
                <p>With BlockerX’s Community feature, you’re not just a spectator—you’re a contributor to a space focused on growth, support, and connection. Ready to make your first post? Follow these steps, choose your post type, and add your voice to the BlockerX community today!</p>
            </section>
        )
    } else {
        return (
            <></>
        )
    }
}

export default CommunityGuide;